// Libraries
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import moment from 'moment'
import { checkPermissions } from '../../../../lib/helper'
import { Row, Col, Spin, Tooltip, PageHeader, Badge, Button } from 'antd'

// Components
import FormSearch from './FormSearch'
import TableLog from './TableLog'
import IconDescModal from './IconDescModal'
import TimeChart from './TimeChart'

// Lib
import { prepareLogFormatArray, prepareDataForTable } from './MainFunction'

// API
import {
  monitorGPSTableLog,
  monitorGPSChartLog,
  monitorGPSCompanyList,
  removeLists,
} from '../actions'

// CSS
import '../assets/css/index.css'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // *เช็คว่ามีการ filter หรือไม่
      isFilter: false,
      visible_form_search: false,

      // *filterObj
      filterObj: {
        FilterCompanyID: '',
        FilterObjStartDate: moment()
          .subtract(6, 'days')
          .format('YYYY-MM-DD'),
        FilterObjEndDate: moment().format('YYYY-MM-DD'),
        FilterVehicleCode: '',
        FilterPlateNo: '',
        FilterDeviceCode: '',
      },

      // *pagination
      paginate: {
        page: 1,
        pageSize: 50,
      },

      // เก็บ array ของวันที่และเดือนที่เลือก
      log_format_data: [],

      // columns
      table_columns: [],

      // *ข้อมูลในตาราง table_log
      log_detail: [],
      total_log_detail: 0,

      // !number of series  ถ้ามีหลาย series ให้มาแก้ไขจำนวน series ที่นี่ด้วย
      number_of_series: 1,

      // icon detail modal
      icon_detail_modal: false,
    }
  }

  componentDidMount() {
    let company_id = this.props.auth.profile.company_id
    if (
      checkPermissions(
        this.props.auth.profile.permissions,
        'monitorGPS',
        'can_special_1'
      )
    ) {
      this.fetchCompanyList()

      company_id = null
    }

    //*first search
    this.handleSearch({
      company_id: company_id,
      search_date: [moment().subtract(6, 'days'), moment()],
      vehicle_code: '',
      plate_no: '',
      device_code: '',
    })
  }

  componentWillUnmount() {
    this.onRemoveLists()
  }
  onRemoveLists = () => {
    this.props.removeLists()
  }

  //*เมื่อมีการเปลี่ยนแปลง state
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.monitorGPS.check_table_log !==
      nextProps.monitorGPS.check_table_log
    ) {
      if (Object.keys(nextProps.monitorGPS.table_log).length > 0) {
        let temp_data = nextProps.monitorGPS.table_log.data || []
        let temp_total = nextProps.monitorGPS.table_log.total || 0
        this.setState(
          {
            log_detail: prepareDataForTable(temp_data) || [],
            total_log_detail: temp_total,
          },
          () => {
            this.prepareColumns()
          }
        )
      }
    }
    return true
  }

  // *ดึงข้อมูล company
  fetchCompanyList = () => {
    this.props.monitorGPSCompanyList({
      accessToken: this.props.auth.accessToken,
    })
  }

  // *ดึงข้อมูล table log
  fetchTableLogData = async () => {
    const { paginate, filterObj } = this.state
    const { page, pageSize } = paginate

    // *fetch data table log
    this.props.monitorGPSTableLog({
      page,
      pageSize,
      filterObj,
      vehicleVisibility: this.props.auth.profile.vehicle_visibility,
      accessToken: this.props.auth.accessToken,
      canFilterCompany: checkPermissions(
        this.props.auth.profile.permissions,
        'monitorGPS',
        'can_special_1'
      ),
    })
  }

  // *เตรียม columns ของ table view
  prepareColumns = () => {
    const { log_format_data } = this.state
    const { color_status } = this.props.monitorGPS
    let table_columns = []
    let all_columns = []

    let index_column = [
      {
        title: '#',
        dataIndex: 'key',
        key: 'key',
        width: 60,
        align: 'center',
        fixed: 'left',
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: (
                <span>
                  {this.state.paginate.pageSize *
                    (this.state.paginate.page - 1) +
                    record.number_index}
                </span>
              ),
              props: {
                align: 'center',
                rowSpan: record.number_of_series || 1,
              },
            }
            return obj
          } else {
            // สำหรับ channel อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: i18n[this.props.language].v.vehicleData,
        dataIndex: 'vehicle_id',
        key: 'vehicles.code',
        width: 130,
        align: 'center',
        fixed: 'left',
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: (
                <div>
                  <Tooltip
                    title={
                      <>
                        <span>{`${
                          i18n[this.props.language].v.vehicleCode
                        } : ${record.vehicle_code || ''}`}</span>
                        <br></br>
                        <span>{`${
                          i18n[this.props.language].v.vehiclePlateNo
                        } : ${record.plate_no || ''}`}</span>
                      </>
                    }
                    placement='topLeft'
                  >
                    <span>{`${record.vehicle_code ||
                      '(' + record.vehicle_code + ')'}`}</span>
                    <br />
                    <span>{record.plate_no || ''}</span>
                  </Tooltip>

                  <br />
                  {record.device_code && (
                    <Tooltip
                      title={
                        <span>{`${
                          i18n[this.props.language].d.deviceCode
                        } : ${record.device_code || ''}`}</span>
                      }
                      placement='topLeft'
                    >
                      <span>{`${record.device_code || ''}`}</span>
                    </Tooltip>
                  )}
                </div>
              ),
              props: {
                align: 'center',
                rowSpan: record.number_of_series || 1,
              },
            }
            return obj
          } else {
            // สำหรับ series อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: i18n[this.props.language].l.lastmaintenanceAt,
        dataIndex: 'vehicle_id',
        key: 'vehicles.code',
        width: 120,
        align: 'center',
        fixed: 'left',
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: (
                <div>
                  {record.maintenance_data && (
                    <Tooltip
                      title={
                        <>
                          <span>
                            {`${record.maintenance_data.type_name_th || '-'}`}
                          </span>
                          <br></br>
                          <span>{`${record.maintenance_data.status_name_th ||
                            '-'}`}</span>
                          <br></br>
                          <span>
                            {`${i18n[this.props.language].d.date} : ${record
                              .maintenance_data.date_display || '-'}`}
                          </span>
                          <br></br>
                          <span style={{ fontSize: '10px' }}>{`(${record
                            .maintenance_data.date_name || '-'})`}</span>
                        </>
                      }
                      placement='top'
                    >
                      <Button
                        type='link'
                        style={{ padding: '0' }}
                        size='small'
                        onClick={() => {
                          window.open(
                            `https://login.terminusfleet.com/map/maintenancedevice/view/id/${record.maintenance_data.id}`,
                            '_blank'
                          )
                        }}
                      >
                        <span>
                          {`${record.maintenance_data.type_name_th || '-'}`}
                        </span>
                      </Button>
                      <br></br>
                      <span>{`${record.maintenance_data.status_name_th ||
                        '-'}`}</span>
                      <br></br>
                      <span>
                        {`${i18n[this.props.language].d.date} : ${record
                          .maintenance_data.date_display || '-'}`}
                      </span>
                      <br></br>
                      <span style={{ fontSize: '10px' }}>{`(${record
                        .maintenance_data.date_name || '-'})`}</span>
                    </Tooltip>
                  )}

                  <div>
                    <Button
                      type='link'
                      style={{ padding: '0' }}
                      size='small'
                      onClick={() => {
                        window.open(
                          `https://login.terminusfleet.com/map/maintenancedevice?plate_no=${record.plate_no}`,
                          '_blank'
                        )
                      }}
                    >
                      <span style={{ fontSize: '10px' }}>
                        {`${
                          i18n[this.props.language].m.maintenanceDeviceHistory
                        }`}
                      </span>
                    </Button>
                    <br></br>
                    <Button
                      type='link'
                      style={{ padding: '0' }}
                      size='small'
                      onClick={() => {
                        window.open(
                          `https://login.terminusfleet.com/map/maintenancedevice/add?vehicle_id=${record.vehicle_id}`,
                          '_blank'
                        )
                      }}
                    >
                      <span style={{ fontSize: '10px' }}>
                        {`${i18n[this.props.language].m.maintenanceDevice}`}
                      </span>
                    </Button>
                  </div>
                </div>
              ),
              props: {
                align: 'center',
                rowSpan: record.number_of_series || 1,
              },
            }
            return obj
          } else {
            // สำหรับ series อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: i18n[this.props.language].d.data,
        dataIndex: 'series_name',
        key: 'series_name',
        width: 60,
        align: 'center',
        fixed: 'left',
        render: (text, record, index) => {
          const obj = {
            children: (
              <Tooltip title={text} placement='top'>
                <span>{text}</span>
              </Tooltip>
            ),
            props: {
              align: 'center',
              rowSpan: 1,
              colSpan: 1,
            },
          }

          return obj
        },
      },
    ]

    all_columns = index_column

    // วนลูปใน log_format_data['order_key']
    for (let i = 0; i < log_format_data['order_key'].length; i++) {
      let key = log_format_data['order_key'][i]
      let date_array = log_format_data[key].date_array
      let day_array = log_format_data[key].day_array

      // วนลูปใน date_array
      for (let j = 0; j < date_array.length; j++) {
        let date = date_array[j]
        let day = day_array[j]

        let column = {
          title: day,
          dataIndex: date,
          key: date,
          align: 'center',
          render: (text, record) => {
            return {
              props: {
                style: {
                  background:
                    record.type == 'gps-sensor' && text
                      ? record[text].map((item, index) => {
                          if (item['color_key'] != 0) {
                            let temp_color = color_status.find(
                              (color) => item['color_key'] == color.key
                            )

                            return temp_color ? temp_color.color : '#fff'
                          } else {
                            return '#fff'
                          }
                        })
                      : '#fff',
                },
              },
              children: (
                <div>
                  {text ? (
                    record[text].map((item, index) => {
                      return record.series_name == 'GPS' ? (
                        <>
                          <Tooltip
                            title={`${
                              i18n[this.props.language].g.gpsActive
                            } : ${
                              item['active_percent'] == -1
                                ? '-'
                                : item['active_percent'] + '%'
                            }`}
                            placement='top'
                          >
                            <Link
                              to={`/monitor-gps/view-detail/${record.vehicle_id}/${item['date']}`}
                              target='_blank'
                            >
                              <Button type='link' size='small'>
                                <span
                                  style={{
                                    fontSize: '13px',
                                    color: 'black',
                                  }}
                                >{`${
                                  item['active_percent'] == -1
                                    ? '-'
                                    : item['active_percent'] + '%'
                                }`}</span>
                              </Button>
                            </Link>
                          </Tooltip>
                          <br />
                          <Tooltip
                            title={
                              <div>
                                <span>
                                  {`${
                                    i18n[this.props.language].g.gpsActive
                                  } : ${item['active_time']} ${
                                    i18n[this.props.language].m.minute
                                  }`}
                                </span>
                                <br />
                                <span>
                                  {`${
                                    i18n[this.props.language].g.gpsNotActive
                                  } : ${item['problem_time']} ${
                                    i18n[this.props.language].m.minute
                                  }`}
                                </span>
                              </div>
                            }
                            placement='top'
                          >
                            <span style={{ fontSize: '11px' }}>
                              {`${item['active_time']}/${item['problem_time']}`}
                            </span>
                          </Tooltip>
                        </>
                      ) : record.series_name == 'Sensor' ? (
                        <>
                          <Tooltip
                            title={`%Sensor : ${
                              item['active_percent'] == -1
                                ? ''
                                : item['active_percent'] + '%'
                            }`}
                            placement='top'
                          >
                            <Button
                              type='link'
                              style={{
                                padding: '0',
                                color: 'rgba(0, 0, 0, 0.65)',
                              }}
                              size='small'
                              onClick={() => {
                                window.open(
                                  `https://login.terminusfleet.com/receiver/test/show_filter_drum_event_v2.php?veh_search=${record.vehicle_code}&f_date=${item['date']}&e_date=${item['date']}`,
                                  '_blank'
                                )
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '13px',
                                  color: 'black',
                                }}
                              >{`${
                                item['active_percent'] == -1
                                  ? ''
                                  : item['active_percent'] + '%'
                              }`}</span>
                            </Button>
                          </Tooltip>
                        </>
                      ) : (
                        <div>
                          <span>{``}</span>
                        </div>
                      )
                    })
                  ) : (
                    <div>
                      <span>{``}</span>
                    </div>
                  )}
                </div>
              ),
            }
          },
        }

        all_columns.push(column)
      }
    }
    table_columns = all_columns

    this.setState(
      {
        table_columns,
      },
      () => {}
    )
  }

  handleChangePage = (page, pageSize) => {
    this.setState(
      {
        paginate: {
          page,
          pageSize,
        },
      },
      () => {
        this.fetchTableLogData()
      }
    )
  }

  // *ค้นหา
  handleSearch = (values) => {
    let isFilter = false
    if (values.vehicle_code || values.plate_no || values.device_code) {
      isFilter = true
    }

    if (
      checkPermissions(
        this.props.auth.profile.permissions,
        'monitorGPS',
        'can_special_1'
      )
    ) {
      if (values.company_id) {
        isFilter = true
      }
    }

    let start_date = moment(values.search_date[0]).format('YYYY-MM-DD')
    let end_date = moment(values.search_date[1]).format('YYYY-MM-DD')

    this.setState(
      {
        filterObj: {
          FilterCompanyID: values.company_id,
          FilterObjStartDate: start_date,
          FilterObjEndDate: end_date,
          FilterVehicleCode: values.vehicle_code,
          FilterPlateNo: values.plate_no,
          FilterDeviceCode: values.device_code,
        },
        paginate: {
          ...this.state.paginate,
          page: 1,
        },
        isFilter,
        visible_form_search: false,

        // *เตรียมข้อมูลวันที่
        log_format_data:
          prepareLogFormatArray(
            this.props.monitorGPS.month_data,
            values.search_date[0],
            values.search_date[1]
          ) || [],
      },
      () => {
        this.fetchTableLogData()
      }
    )
  }

  // *เปิด form search
  handleOpenFormSearch = () => {
    this.setState({ visible_form_search: true })
  }

  // *ปิด form search
  handleCloseFormSearch = () => {
    this.setState({ visible_form_search: false })
  }

  // *เปิด modal รายละเอียด icon
  handleOpenIconDetailModal = () => {
    this.setState({
      icon_detail_modal: true,
    })
  }

  // *ปิด modal รายละเอียด icon
  handleCloseIconDetailModal = () => {
    this.setState({
      icon_detail_modal: false,
    })
  }

  render() {
    const {
      isFilter,
      visible_form_search,
      paginate,
      table_columns,

      log_detail,
      total_log_detail,
      icon_detail_modal,
    } = this.state

    const { check_table_log, color_status } = this.props.monitorGPS

    return (
      <AuthorizeComponent {...this.props} matching_name='monitorGPS'>
        <GeneralStyledContent>
          <div className='monitor-gps'>
            {/* header */}
            <PageHeader
              style={{ paddingLeft: '0px' }}
              title={
                <div className='header__text'>
                  {i18n[this.props.language].m.monitorGPS}
                </div>
              }
            />

            {/* ปุ่มค้นหาแถบบน */}
            <Row>
              <Col span={10}>
                <Button
                  type='primary'
                  className='edge-element'
                  size='small'
                  onClick={this.handleOpenIconDetailModal}
                  icon='dot-chart'
                >
                  {i18n[this.props.language].i.iconDesc}
                </Button>
              </Col>
              <Col span={14} style={{ marginBottom: '10px' }} align='right'>
                <Badge dot={isFilter} align='right'>
                  <Button
                    icon='filter'
                    size='small'
                    align='right'
                    onClick={this.handleOpenFormSearch}
                  >
                    <span>{i18n[this.props.language].s.search}</span>
                  </Button>
                </Badge>
              </Col>
            </Row>

            {/* table log */}
            <Row>
              <Spin
                spinning={check_table_log}
                tip={`${i18n[this.props.language].l.loading}...`}
              >
                <TableLog
                  paginate={paginate}
                  data={log_detail || []}
                  total={total_log_detail}
                  handleChangePage={this.handleChangePage}
                  column={table_columns}
                />
              </Spin>
            </Row>

            {/* form search */}
            <FormSearch
              handleSearch={this.handleSearch}
              visible={visible_form_search}
              handleClose={this.handleCloseFormSearch}
            />

            {/* แสดงรายละเอียดสี */}
            <IconDescModal
              icon_detail_modal={icon_detail_modal}
              handleCloseIconDetailModal={this.handleCloseIconDetailModal}
              color_status={color_status}
            />
          </div>
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

export default connect(
  ({ monitorGPS, ui: { language }, auth, auth: { profile } }) => ({
    monitorGPS,
    language,
    auth,
    profile,
  }),
  {
    monitorGPSTableLog: monitorGPSTableLog.request,
    monitorGPSChartLog: monitorGPSChartLog.request,
    monitorGPSCompanyList: monitorGPSCompanyList.request,
    removeLists,
  }
)(Index)
