const en = {
  a: {
    alertWhenDeviatingFromRoute: "Alert When Deviating From Route",
    alertWhenReachingDesticationLaterThanScheduled:
      "Alert When Reaching Destication Later Than Scheduled",
    alertVehiclesEnteringTheAreaLaterThanSpecified:
      "Alerts vehicles entering the area later than specified",
    approachRange: "Approach Range (Mater)",
    accRedPolicy: "Acceleration (km/h in 1 second)",
    Act: "Act",
    Actno: "Act no.",
    AverageTrip: "Average Trip",
    addLocation: "Add Location",
    editLocation: "Edit Location",
    allTruck: "All TrucK",
    addFromRouteMaster: "Add From Route Master",
    accessory: "Accessory",
    acceleration: "Acceleration",
    account: "Account",
    accountName: "Account Name",
    ACCUMULATEEARNINGSTATISTICS: "ACCUMULATE EARNING STATISTICS",
    action: "Action",
    actionRequired: "Action Required",
    actionTime: "Action Time",
    active: "Active",
    activeStatusImage: "Active Status Image",
    actualTransitDistance: "Actual Transit Distance",
    add: "Add",
    addARow: "Add A Row",
    addDrivinglicense: "Add Driving License",
    addInformation: "Add Information",
    address: "Address",
    addItemInList: "Add Item to List",
    addVehicleGroup: "Add Vehicle Group",
    adjustTheTable: "Adjust The Table",
    administrator: "Administrators",
    advancedSetting: "Advanced Settings",
    affiliation: "Affiliation",
    african: "African",
    after1day: "After 1 Day",
    after1hour: "After 1 Hour",
    after30min: "After 30 Min.",
    after4hour: "After 4 Hour",
    after5min: "After 5 Min.",
    after8hour: "After 8 Hour",
    agency: "Agency",
    alarm: "Alarm",
    alarmAndAlertReportClassifiedByDailyCarRegistration:
      "Alarm and Alert Report Classified by Daily Car Registration",
    alarmAndAlertReportClassifiedByYearlyCarRegistration:
      "Alarm and Alert Report Classified by Yearly Car Registration",
    alert: "Alert",
    alert_setting_filter: "Please set filter setting report",
    alertAlarm: "Alert Alarm",
    alertNoti: "Alert Only",
    aliasTableCompany: "Alias",
    all: "All",
    allowParkingInside: "Allow Parking Inside Only",
    allowParkingOutside: "Allow Parking Outside Only",
    allowPTODoorInside: "Allow Open Inside Only",
    allowPTODoorOutside: "Allow Open Outside Only",
    allowSharedPartnerEdit: "Partner can Edit",
    allowWorkingTime: "Allow Working Time",
    allStatusDashCameras: "all",
    amount: "Amount",
    amountPlant: "Amount Plant",
    amountPlantIsRequired: " Amount Plant is Required",
    amphur: "District",
    and: "AND",
    announcement: "Announcement",
    announcementBroadcast: "Announcement Broadcast",
    apply: "Apply",
    appointment_date: "Appointment Date",
    appointment_place: "Appointment place",
    appointmentLocation: "Appointment Location",
    arab: "Arab",
    assignAt: "Assign at",
    assignFirmware: "Assign Firmware",
    assignTo: "Assign To",
    attachment_type: "Attachment Type",
    available: "Available",
    avgEarningJob: "Avg. Earning/Job",
    allowTechniciansToUse: "Allow Technicians To Use",
    allow: "Allow",
    autoUploadSetting: "Auto Upload Setting",
    automaticaddeditdriverinformationbyusingdriverlicense:
      "Automatic Add/Edit Driver Information by Using Driver License ",
    automaticeditvehiclebyusingdriverlicense:
      "Automatic Edit Vehicle by Using Driver License",
    assignedBy: "Assigned by",
    audio: "Audio",
    AdasEventReport: "Event ADAS+DMS",
    alwaysRecording: "Always recording",
    alwaysRecordingLowPower: "Always recording (Low power)",
  },
  b: {
    brakeRedPolicy: "Brake (km/h within 1 second)",
    back: "Back",
    base: "Base",
    baseTemplate: "Base Template",
    behavior: "Behavior",
    behavior_report_menu: "Behavior",
    boatDeliveryDashboard: "Boat Delivery Status",
    boatDeliveryReport: "Boat Delivery Report",
    body_email: "Body",
    bodyNo: "Body No.",
    bodyNoOfInstall: "Body no of install",
    bookedDate: "Booked Date",
    booking: "Booking",
    bookingDate: "Booking Date",
    bookingOverview: "Booking Overview",
    bookingPool: "Booking Pool",
    bookingRemark: "Booking Remark",
    BOOKINGSTATISTICS: "BOOKING STATISTICS",
    BOOM: "BOOM",
    boxID: "Device ID",
    boxNumber: "Device No.",
    brake: "Brake",
    brand: "Brand",
    broken: "broken",
    button: "Search",
    battery: "Battery",
    battery_car: "Car Battery",
    battery_samrong: "Battery Samrong",
    battery_is_charge: "Battery is charge",
    battery_not_charge: "Battery is not charge",
    battery_status: "Battery Status",
    browseFiles: "Browse Files",
    brokenFile: "Broken File",
  },
  c: {
    car_registration_page: "Car Registration Page",
    cause: "Cause",
    currentPlant: "Current Plant",
    complete: "Complete",
    cardId: "Card number : ",
    chauffeurDriver: "Driver",
    chauffeurDriverUnKnown: "Driver name unknown",
    camera_back: "Back",
    camera_extra: "Extra",
    camera_position: "Camera Postion",
    cancel: "Cancel",
    cancelled: "Cancelled",
    cancelReason: "Reason Of Cancellation",
    carNotOnline: "Car not online",
    carTax: "Car tax",
    category: "Category",
    cc_email: "Cc",
    changeLanguage: "Change Language",
    changePassword: "Change Password",
    changeProduct: "Change Product",
    channel: ["Channel", "Channel"],
    chasisSerialNumber: "Chasis Serial Number",
    chauffeur: "Driver",
    chineseKoreanJapanese: "Chinese/Korean/Japanese",
    clear: "Clear",
    clearUp: "Clear Up",
    close: "Close",
    closed: "Closed",
    closeFixed: "Close:Fixed",
    closeNotAvailable: "Close : Not available",
    closeJob: "Off work",
    closeJobDescribe: "Are you sure to close this task?",
    cmd: "CMD",
    collapse: "Collapse",
    Color: "Color",
    comment: "Description",
    communityArea: "Community Area",
    company: "Company",
    companyBilling: "Company Billing",
    companyCode: "Company Code",
    CompareVehicleSccoReport: "Compare Vehicle SCCO Report",
    compulsory: "Compulsory",
    consignee: "Consignee",
    consigneePhone: "Consignee Phone",
    concreteDeliveryDashboard: "Concrete Delivery Status",
    configuration: "Configuration",
    confirm: "Confirm",
    confirmSendingBy: "Sending Confirm By",
    confirm_appointment_place: "Confirm appointment place",
    confirm_type: "Confirm type",
    confirmNewPassword: "Confirm New Password",
    confirmPassword: "Confirm Password",
    confirmToAddThisItem: "Confirm To Add This Item.",
    confirmToDeleteThisItem: "Confirm To Delete This Item.",
    confirmToEditThisItem: "Confirm To Edit This Item.",
    confirmToCancelThisItem: "Confirm To Cancel This Item.",
    confirmToCloseThisItem: "Confirm To Close This Item.",
    connection: "Connection",
    connectionLost: "Connection lost",
    contact: "Contact",
    contact_name: "Contact name",
    contact_telno: "Contact telno",
    Contactmanagement: "Contact Management",
    contactName: "Contact Name",
    contactSupportForUserThisMenu: "Please Contact Support To Use This Feature",
    contactTelno: "Contact Tel No",
    continuousDrive: "Continuously Drive",
    continuousDriveInADay: "Continuously Drive In a Day",
    continuousDriving: "Continuous Driving",
    contractor: "Contractor",
    coordinates: "Coordinates",
    couldNotDisplayDirections:
      "Could not display directions due to: ZERO_RESULTS",
    courseName: "Course Name",
    create_trip: "Create trip",
    created_by: "Created by",
    createdAt: "Created At",
    createdBy: "Created By",
    createFrimware: "Create Frimware",
    criteria: "Criteria",
    criterionAndDeductMarksForTripSuccess:
      "Criterion and Deduct Marks for Trip Success",
    criticality: "Criticality",
    criticalLevel: "Critical Level",
    crossroad: "Junction",
    currentAddress: "Current Address",
    currentVehicle: "Current Vehicle",
    custom: "Custom",
    customer: "Passenger",
    customerDetail: "Passenger Detail",
    customerID: "Customer ID",
    customerName: "Passenger Name",
    customerType: "Customer Type",
    cycleOfDrumRotation: "Cycle of drum rotation",
    creator: "Creator",
    copyLink: "Copy Link",
    calibratefuel: "Calibrate Fuel Auto",
    condition_engineoff: "Engine off only",
    cpfDashboard: "CPF Dashboard",
    creditamount: "Credit Amount",
    current_creditamount: "Current Credit Amount",
    credit_available: "Credit Available",
    companysetting: "Company Setting",
    cut_fuel_engine_on_off: "Cut fuel Engine On/Off",
    cameraPosition: "Camera position",
    cameraStatusDashboard: "Camera status dashboard",
    cameraPowerOff: "Cut off camera power",
    cameraName: "Camera name",
    createdSuccess: "Create success",
    createdFail: "Create failed",
  },
  d: {
    disableNotification: "Muted",
    drivingSpeed: "Driving Speed",
    drivingTimePolicy: "Driving Time Policy",
    drivingTimeWarning: "Driving Time Warning",
    distanceNotice: "Time interval for driver alerts",
    drive_surge: "Drive Surge",
    driverDashboard: "Driver Dashboard",
    downloading: "Downloading",
    driverLicenseExpireDate: "Driver License Expire Date",
    driverData: "Driver Data",
    DrivingNamelicensescanlogReport: "Name From Driving License : ",
    durationRange: "Duration Range",
    daily: "Daily",
    DailyAlarmAndAlertReportByDriver: "Daily Alarm and Alert Report by Driver",
    dailyCarStatusReport: "Daily Car Status Report",
    dailyDrivingBehaviorReport: "Daily Driving Behavior Report",
    DailyiconsiamReport: "Shuttle Boat – Shuttle Bus Service Daily Report",
    DailyReport: "Trip Daily Report",
    dangerous: "Dangerous",
    dashboard: ["Dashboard", "Dashboard"],
    dashCamera: "Dash Camera",
    data: "Data",
    dataForDLT: "Data For DLT",
    dataSource: "Data Source",
    dataType: "Type",
    dataTable: "Data Table",
    dataVisibility: "Data Visibility",
    date: "Date",
    date_entry: "Entry date",
    date_out: "Out date",
    date_time: "Date Time",
    date_time_entry: "Date-time entry",
    date_time_out: "Date-time out",
    dateEnd: "Date End",
    dateOfBirth: "Date of Birth",
    dateOfSubmission: "Request Date",
    dateRange: "Date Range",
    dateRangeNotBeMoreThan31Days:
      "The selected date range should not be more than 31 days",
    dateStart: "Date Start",
    dateSubmit: "Delivery Date",
    dateTime: "Date/Time",
    day: "Day",
    daYouWantToReActive: "Do You Want To Re-Active?",
    daysBeforeNotification: "Remind Day",
    dayTime: "Day Time",
    ddHhMm: "(day:hour:min)",
    deduct: "Deduct",
    default: "Default",
    defaultImageBylocationType: "Default Image By Location Type",
    defaultLang: "Default lang",
    defaultLatitude: "Default latitude",
    defaultLongitude: "Default longitude",
    delete: "Delete",
    deletedAt: "Deleted At",
    deletedBy: "Deleted By",
    deleteInformation: "Delete Information",
    deleteReason: "Delete Reason",
    deliver: "Deliver",
    deliver_duration: "Start at - End at",
    deliver_status: "Deliver status",
    Delivered: "Delivered",
    deliveryAddress: "Delivery Address",
    deliveryItem: "Delivery Item",
    deliveryItemDeleteHistoryPool:
      "Delivery Item Delete History Pool (EZY GPS)",
    deliveryItemPool: "Delivery Item Pool (EZY GPS)",
    DeliveryReport: "Trip Delivery Report",
    deliveryStatus: "Delivery Status",
    deliverytrip: "Delivery Trip",
    descrip: "Description",
    description: "Description",
    destination: "Destination",
    destinationDetail: "Destination Detail",
    destinationLocation: "Destination",
    destinationLocation_require: "Destination is require",
    destinationLocation_duplicate_require: "Destination is duplicate",
    destinationLocationcode: "Destination code",
    detail: "Detail",
    detail_problem: "Detail problem (other)",
    detailOfConcreteTruck: "Detail Of Concrete Truck",
    detailproblem: "Detail Problem",
    detailProblemServiceMoveDevice: "Detail Problem",
    device: "Device",
    deviceName: "Device Name",
    devicePhone: "Device Phone",
    deviceType: "Device Type",
    depositFuelcreditReason: "Reason of deposit",
    diff: "Diff",
    dischargeDenominator: "Discharge Denominator",
    disconnected: "Disconnected",
    displayTime: "Display Time",
    distance: "Distance",
    distanceWithKm: "Distance (km)",
    distance_less_than: "Distance less than",
    distance_more_than: "Total distance is considered work/day (km)",
    DistfromlastmaintenanceReport: "Distance from Last Maintenance Report",
    doc_no: "Document No.",
    document: "Document",
    done: "Done",
    doNotInstall: "Do not install",
    doNotParkBesideTheRoad: "No Parking On Sideways",
    doNotSwipeCardsOrDamageCards: "Please Check Your Card",
    download: "Download",
    downloadcertificate: "Download Certificate",
    doYouNeedToRebootTheseItems: "Do You Need to Reboot These Items?",
    doYouWantToAddTheseItems: "Do You Want To Add These Items?",
    doYouWantToCancel: "Do You Want To Cancel?",
    doYouWantToConfirmSend: "Do You Want To Confirm Send?",
    doYouWantToDeleteTheseItems: "Do You Want To Delete These Items?",
    doYouWantToEditTheseItems: "Do you want to edit these items?",
    doYouWanttoReActive: "Do You Want To Reactivate?",
    doYouWantToSendAnEmailToUsersToVerifyTheirIdentity:
      "Do you want to send an email to users to verify their identity?",
    DozeoffriskReport: "Doze Off Risk Report",
    draft: "Draft",
    draw: "Draw",
    driveContinuouslyForMoreThan10HoursDay: "Driving Time > 10 hrs/day",
    driveContinuouslyForMoreThan4Hours: "Driving Time > 4 hrs",
    driveOverTheSpeedLimitInTheCrossroads:
      "Drive Over The Speed Limit In The Crossroads",
    driver1: "Driver 1",
    driver2: "Driver 2",
    driverCode: "Driver Code",
    driverHistory: "Diver History",
    DriverinformationReport: "Driverinformation Report",
    driver: "Driver",
    driverName: "Driver Name",
    driverPhoneNumber: "Phone Number",
    driveTooFastInTheCommunity: "Drive Too Fast In The Community",
    driveTooFastInTheNormalRoute: "Drive Too Fast In The Normal Route",
    driving: "Driving",
    DrivingdistanceReport: "Driving Distance Report",
    DrivinghourReport: "Driving Hour Report",
    drivingIiceneType: "Driving Licene Type",
    drivingIicenseNumber: "Driving License Number",
    drivinglicense: "Driving License",
    drivinglicenseexpire: "Driving License Expire",
    drivingLicenseNumberMustBeNumber:
      " Driving License Number Must Be a Number",
    drivingLicenseNumberMustBeNumberandAlphabet:
      " Driving License Number Must Be a Number and Alphabet",
    DrivingLicenseExpireReport: "Driving License Expire Report",
    DrivinglicensescanlogReport: "Driving License Scan Log Report",
    DrivinglogReport: "Driving Log Report",
    DrivinglogReport2: "Driving Log Report 2",
    DrivinglogspecialReport: "Driving Log Report (New)",
    DrivingoverlimitReport: "Driving Over Limit Report",
    drivingPeriodRange: "Driving Period Range (min)",
    drivingTime: "Driving Time",
    drumCapacity: "Drum Capacity",
    duration: ["Duration", "Duration"],
    durationMin: "Duration(Hour:Minute)",
    duration_time: "Duration Time",
    durationOfPTOGate1On: "Duration of PTO/Gate1 On",
    mdvrdownloadfile: "Download Video MDVR",
    drivingcontinuous: "Driving continuous more 4 hours",
    dataType: "Data Type",
    driverincome: "Driver Income",
    displaySetting: "Display Setting",
    displayMarkerTitle: "Display Title Marker",
    defaultPage: "Default Page",
    dataSetting: "Data Setting",
    dataFreqEngineOn: "Data Frequency Engine On",
    dataFreqEngineOff: "Data Frequency Engine Off",
    deviceConfig: "Device Config",
    db_connect_status: "Database Connection Status",
    depositfuelcredit: "depositcredit",
    depositfuelcreditreasonname: "Deposit reason",
    deviceCode: "Device code",
    dateTimeSnapshot: "Date time snapshot",
  },
  // device_config required
  dcRequired: {
    video_type: "Please specify video type",
    video_receive_size: "Please specify storage size(GB)",
    image_resolution: "Please specify resolution of image",
    image_position: "Please specify image position",
    image_update_freq: "Please specify update frequency(minutes).",
    keep_image: "Please specify how long to keep image.",
    incoming_call_response: "Please specify type of response(incoming calls)",
    incoming_call_response_auto_type: "Please specify autoresponder type.",
    emergency_telephone_number: "Phone number is not valid",
    numberOnly: "Please enter numbers only",
    updateSuccess: "Update success",
    updateFail: "Update failed",
    icingChannelSelect:
      "Can only be selected when the previous channel has been opened.",
  },
  e: {
    electricityUse: "Electricity Use",
    export: "Export",
    EstimatedTime: "Estimated Time",
    error: "Error",
    editTable: "Edit Table",
    earningOverview: "Earning Overview",
    EarningReceivedSTATISTICS: "Earning/Received STATISTICS",
    earningType: "Earning Type",
    edit: "Edit",
    editPassword: "Edit Password",
    editStatus: "Edit Status",
    efficiency_report_menu: "Efficiency",
    email: "Email",
    email_invalid: "Email is invalid",
    email_require: "Email is required",
    email_subject: "Email Subject",
    email_subject_require: "Email subject is required",
    emailAE: "Email AE",
    emailIsInvalid: " Email is invalid",
    emailVerification: "Pending",
    emailVerificationStatus: "Email Verification Status",
    emailVerificationSuccessful: "Verification Successful",
    emergencyCase: "Emergency Case",
    emergencyStatus: "Emergency Status",
    employeeID: "Employee ID",
    employeeInformation: "Employee Information",
    endAt: "End at",
    endDate: "End Date",
    endDestinationCode: "End Location Code",
    endDestinationName: "End Location Name",
    endLocationCode: "End Location Code",
    endLocationName: "End Location Name",
    endValidDate: "End Valid Date",
    energy_report_menu: "Energy",
    engine: "Engine",
    engine_off_time_end_at: "Engine off time end at",
    engine_off_time_start_at: "Engine off time start at",
    engine_on_time_end_at: "Engine on time end at",
    engine_on_time_start_at: "Engine on time start at",
    engineOff: "Engine Off",
    engineOffDesc: "GPS sending position while engine off.",
    engineOn: "Engine On",
    engineOnDesc: "GPS sending position while engine on.",
    engineOnDuration: "Engine-on duration",
    engineOnWithImg: "Engine On with Image",
    engineOnWithImgDesc:
      "GPS sending position while engine on with Dash camera snapshot images.",
    engineStatus: "Engine Status",
    estFare: "Est. Fare",
    estTransitDistance: "Travel Distance",
    estTransitDuration: "Travel Duration",
    etc: "Etc.",
    europeAmerican: "Europe/American",
    event: "Event",
    eventGraph: "Event Graph",
    eventGraph2: "Speed Fuel Temperature Summary Graph",
    eventAlarm: "Event Alarm",
    eventEnd: "Event End",
    eventMap: "Event Map",
    EventReport: "Event Report",
    excel: "Excel",
    expand: "Expand",
    expense: "Expense",
    expenseBaht: "Expense (Baht)",
    expired: "Expired",
    expirein1month: "Expire in 1 month",
    expirein3month: "Expire in 3 month",
    expireDate: "Expire Date",
    expiryDate: "Expiry Date",
    export_date_at: "Issue date",
    extra: "Extra",
    emergencyCall: "Emergency Telephone Number(Outgoing call)",
    emergencyCallReserve: "Emergency Telephone Number Reserve(Outgoing call)",
    extendCameraSetting: "Extend Camera Setting",
    engine_on_only: "Engine-on only",
    endTime: "End time",
    expireperiod: "Expire period",
    expire_document_status: "Expire Status",
    engineOnVideoType: "Engine on video type",
    engineOffVideoType: "Engine off video type",
    engineOnRecording: "Engine on recording",
  },
  extraSetting: {
    extraSetting: "Extra Setting",
    min: "(min)",
    turn_left: "Turn Left",
    turn_right: "Turn Right",
    max: "(max)",
    duration: "Duration(seconds)",
    horn: "Horn",
    lowLight: "Low Beam Lights",
    highLight: "High Beam Lights",
    backupLight: "Back-up Light",
    breakLight: "Break Light",
    wiper: "Wiper",
    mute: "Mute  Sound",
  },

  event: {
    acc: "ACC",
    approaching_point: "Approaching point",
    approaching_point_but_late: "Approaching point but late",
    arrival_but_late_departure: "Arrival but late departure",
    arrival_point: "Arrival point",
    blackbox_restart: "Blackbox Restart",
    boom_ready: "Boom ready",
    discharge_concrete: "Discharge concrete",
    driver_rest_lower_than_10_hours: "Driver rest lower than 10 hours",
    engineoff: "EngineOFF",
    engineon: "EngineON",
    fuel_anomaly: "Fuel anomaly",
    hard_brake: "Hard brake",
    hard_turn: "Hard turn",
    high_beam_off: "High beam off",
    high_beam_on: "High beam on",
    high_rpm: "HiGH RPM",
    hit_the_brake: "Hit the brake",
    honk: "Honk",
    into_and_out_of_specific_areas: "Into and out of specific areas",
    late: "Late",
    long_idle_time: "Long idle time",
    low_beam_off: "Low beam off",
    low_beam_on: "Low beam on",
    low_speed_more_than_10_minute: "Low speed more than 10 minute",
    mixing_concrete: "Mixing concrete",
    move_in_point_late: "Move in point late",
    move_out_load_point_with_out_ticket: "Move out load point with out ticket",
    move_out_point_late: "Move out point late",
    off_route: "Off route",
    open_door: "Open door",
    open_door_outside_point: "Open door outside point",
    over_driving_hour_limit: "Over driving hour limit",
    poweroff: "PowerOFF",
    poweron: "PowerON",
    pto_off: "PTO Off",
    pto_on: "PTO On",
    pump_on: "Pump on",
    refuel_full: "Refuel (full)",
    refuel_not_full: "Refuel (not full)",
    release_the_brake: "Release the brake",
    reversing_light_off: "Reversing light off",
    reversing_light_on: "Reversing light on",
    shock: "Shock",
    speed_anomaly: "Speed anomaly",
    stop_honk: "Stop honk",
    stop_in_area: "Stop in area",
    stop_mixing_concrete: "Stop mixing concrete",
    summary_idle_limit: "Summary idle limit",
    summary_low_speed_more_than_10_minute:
      "Summary low speed more than 10 minute",
    summary_over_driving_hour_limit: "Summary over driving hour limit",
    temperature_anomaly: "Temperature anomaly",
    truck_stay_in_plant_more_than_limit: "Truck stay in plant more than limit",
    turn_signal_off: "Turn signal off",
    turn_signal_on: "Turn signal on",
    turn_left_signal_on: "Turn left signal on",
    turn_left_signal_off: "Turn signal off",
    turn_right_signal_on: "Turn right signal on",
    turn_right_signal_off: "Turn signal off",
    unauthorized_discharge: "Unauthorized discharge",
    windshield_wipers_off: "Windshield wipers off",
    windshield_wipers_on: "Windshield wipers on",
    work_longer_than_the_time_limit: "Work longer than the time limit",
    working_distance_less_than_the_rule: "Working distance less than the rule",
    working_over_limit: "Working over limit",
    working_within_a_given_period: "Working within a given period",
  },
  f: {
    fuelUsage: "Fuel Usage",
    firstLogOn: "First Logon",
    finishQueue: "Finish Queue",
    filterSearch: "Filter",
    findCarThisArea: "Find Car this Area",
    facilityName: "Location Name",
    facilityName2: "Location Name2",
    facilityName3: "Location Name3",
    fail: "Fail",
    fare: "Fare (฿)",
    file: "File",
    file_location: "File Location",
    file_size: "File Size",
    file_size_small: "Small",
    file_size_large: "Large",
    fileapetition: "File a petition",
    fileName: "File name",
    filenotreadydownload: "Cannot download at this time",
    fillInfomation: "Fill Infomation",
    fillInForm: " Fill in Form",
    fillInFrom: "Fill In From",
    fillPosition: "Fill Position",
    filter: "Filter",
    filter_by: "Filter by",
    filter_type: "Filter type",
    filter_type1: "First move out plant to last move in plant",
    filter_type2: "First engine on to last engine off",
    findCustomer: "Search passenger",
    findDistance: "Find Distance",
    findSpace: "Find Space",
    fine: "Fine",
    finish: "Finish",
    finished: "Finished",
    finishRefuel: "Finish Refuel",
    firmware: "FirmWare",
    firmwareNumber: "Firmware Number",
    firmwareVersion: "Firmware Version",
    first_dest: "Start Position",
    firstMaintenanceConditions: "First maintenance conditions",
    firstName: "First Name",
    firstNameisCharacters: " First Name must be characters",
    fixed: "Fixed",
    flateNumber: "Flate Number",
    flatRate: "Flat-Rate",
    forceUpdate: "Force Update",
    forgot_password: "Forgot Password",
    front: "Front",
    fuel: "Fuel",
    fuelA: "Fuel A",
    fuelB: "Fuel B",
    fuelC: "Fuel C",
    fuelFull: "Fuel Full Report",
    fuelRate: "Fuel Rate",
    fuelremainrateReport: "Fuel Remain Rate Report",
    fuelNotifyReport: "FuelNotify Report",
    fuel_consumption: "Cumulative Fuel Consumption",
    fuelTankSize: "Fuel Tank Size",
    fuelType: "Fuel Type",
    fuelUsage: "Fuel Usage",
    Fullscreen: " Fullscreen",
    fullTank: "Full Tank",
    fullScreen: "Full Screen",
    fullScreenExit: "Exit Full Screen",
    fueltank: "Fuel Tank Size",
    fueltankRequire: "Fuel Tank Size is require.",
    fueladd: "Fuel Add",
    fueladdRequire: "Fuel Add is require.",
    fleetCard: "Fleet Card",
    fleetCardCode: "fleet card code",
    fuel1: "Fuel1",
    fuel2: "Fuel2",
    fuelCreditForm: "Deposit Credit Form",
    filterLocationType: "Filter location type",
    fileSize: "File size",
    format_sheet_seperate: "Seperate Sheet",
    format_sheet_single: "Single Sheet",
    firstDischargeAt: "First discharge at",
  },
  g: {
    Graph: "Graph",
    gallery: "Gallery",
    gallery_name: "Name",
    gate1: "Door 1",
    gate2: "Door 2",
    gate3: "Door 3",
    general: "General",
    general_report_menu: "General",
    generalLocation: "General Location",
    generateMap: "Generate Map",
    getVehicles: "Get Vehicles!",
    goBackToUseImagesByLocationType: "Go Back To Use Images By Location Type",
    googleMapApiKey: "Google Map Api Key",
    gps_update: "GPS Update",
    gpsCertification: "GPS Certification",
    gpsCertRequest: "GPS Cert. Request (EZY GPS)",
    gpsCertRequestHistory: "GPS Cert. Request History (EZY GPS)",
    gpsNotFixed: "GPS Not Fixed",
    gpsNotFixedDesc: "Position Inaccurate.",
    GpsstatusReport: "GPS Status Report",
    gradeAtoF: "Grade (A to F)",
    greater_than: "Greater than",
    greaterOrEqual: "Greater or equal",
    group: "Group",
    group_by: "Group by",
    group_data: "Group Data",
    group_idle: "Only Idle",
    group_speed: "Only Overspeed",
    groupByLocation: "Group By Location",
    groupVehicleType: "Vehicle Group/Type",
    googleSetting: "Google Setting API",
    graphsonsors: "Graph Sensors",
    gpsIsFixed: "GPS not fixed",
    gpsTime: "GPS time",
    gpsPerformanceDesGPS:
      "Calculated from usage during the running time of each ticket. by deducting the time before notifying the repair In the event of a loss of contact, it is not possible to time the work. The working time for each ticket will be calculated from the time of production + 3 hours.",
    gpsActive: "GPS active",
    gpsNotActive: "GPS inactive",
  },
  h: {
    hardwareID: "Hardware ID",
    header: "Header",
    height: "Height",
    help_menu: "Helps",
    high: "High",
    highRPM: "High RPM",
    history_servicerepairdevice: "Historys",
    home: "Home",
    honeytoastdownloadfile: "Download Video Honey Toast",
    honeytoastlivestream: "Honey Toast Live Stream",
    honeytoastplayback: "Playback Video",
    horsePower: "Horse Power",
    hotel: "Hotel",
    hour: "Hours",
    hours: "Hours",
    hotspot: "Hotspot",
    heightMain: "Height main",
    heightSub: "Height sub",
  },
  i: {
    iconDesc: "Icon Desc.",
    id: "ID",
    idcard: " ID card",
    idleFuelRate: "Idle Fuel Rate",
    ignoredAlert: "Ignored",
    imageFromDashCamera: "Dash Camera",
    imageFromTheGallery: "Image From The Gallery",
    imageMustSmallerThan5MB: "Image Must Smaller Than 5MB!",
    imagesNotUpdate: "Images not update",
    immediatelyAcess: "Immediately",
    include_engine_off: "Include engine off data",
    indexFilterOption_All: "All",
    indexFilterOption_Cancel: "Cancel",
    indexFilterOption_Confirm: "Confirm",
    indexFilterOption_Finish: "Finish",
    indexFilterOption_Unassign: "Unassign",
    indexFilterOption_Waiting: "Waiting Confirm",
    indian: "Indian",
    inEvery: "in Every",
    infomation: "Information",
    inoutlocationreport: "In out location report",
    inoutlocation: "In out location",
    inside: "Inside",
    insidePolicy: "within the Location",
    install: "Install",
    install_at: "Install at",
    installDevice: "Install Device",
    installed: "Installed",
    installStatus: "Install Status",
    insurance: "Insurance",
    insurance_report_menu: "Insurance",
    insuranceID: "Insrance ID",
    InsuranceReport: "Insurance Report",
    insure_report_menu: "Insurance",
    internalOperation: "Internal Operation",
    invoice_date: "Invoice Date",
    invoice_number: "Invoice number",
    issuedDate: "Issued Date",
    itemLeft: "Not Select",
    itemSelected: "Select",
    import: "Import",
    incident: "Incident",
    incidentShow: "Display on Incident Dashboard",
    incidentReport: "Incident Report",
    imageResolution: "Image resolution",
    imagePosition: "Image position",
    imageUpdateFreq: "Image update frequency(min)",
    imageKeep: "Keep image(day)",
    incomingCall: "Incoming call response",
    incomingCallAuto: "Incoming call response auto type",
    installCameraInsideCar: "Install camera inside car",
    insideCameraPassword: "Password",
    imageSetting: "Image setting",
    image_must_smaller_than_512: "Image must smaller than 512KB!",
    insideCameraType: "Inside camera type",
    insideCameraUser: "Username",
    insideCameraPath: "Path",
    insideCameraPort: "Port",
    InputactivityReport: "PTO/Gate activity",
    insufficientCredit: "insufficient Credit",
    insuranceAndTaxes: "Insurance and Taxes",
    insideCameraModel: "Inside camera model",
  },
  j: {
    jobDetail: "Job Detail",
    jobId: "Job ID",
    jobStartAt: "Job Start At",
    jobStatus: "Job Status",
    jobType: "Job Type",
    jobTypeSTATISTICS: "Job Type STATISTICS",
    joinDate: "Join Date",
    job_order_name: "Job Order Name",
  },
  k: {
    keywordAtleast3character: "Keyword At Least 2 Character",
    kind: "Kind",
    km: "km.",
    kmH: "km/h",
    kmHrS: "km/hr/s",
    kpiDateTime: "Date/Time",
    kpiEvent: "Event",
  },
  l: {
    lastDrive: "Last Drive",
    lastUpdated: "Last Updated",
    latestLogoff: "Latest Logoff",
    linkDownload: "Link Download",
    lastest_signal: "Lastest Pos",
    lastmaintenanceAt: "Last Maintenance At",
    lastName: "Last Name",
    LastNameisCharacters: " Last Name must be characters",
    lastRequestOn: "Latest Request At",
    lat: "Lat",
    late: "Late",
    latePoint: "Late Point Criteria",
    latestDriver: "Latest Driver",
    latitude: "Latitude",
    latitudeIsRequired: " Latitude is Required",
    latitudeIsRequiredAndCantBeEmpty:
      " Latitude is required and can't be empty",
    lazada: "Lazada",
    length: "Length",
    less_than: "Less than",
    lessOrEqual: "Less or equal",
    licenseStatus: "License Status",
    lightStatus: "Light Status",
    limit10Driver: " Limit 10 driver",
    limitAggesiveAccelerationThreshold: "Aggesive Acceleration Threshold",
    limitDrivingAggesiveThreshold: "Driving Aggesive Threshold",
    limitDrivingHourTime: "Limit driving hour time (minutes)",
    limitGoInsideArea: "Limit Inside Area Duration",
    limitGoOutsideArea: "Limit Outside Area Duration",
    limitOpenDuration: "Limit Duration",
    limitParking: "Limit Parking",
    limitParkingDuration: "Limit Duration",
    limitParkingInsideLocation: "Limit Parking Inside Location",
    limitParkingOutsideLocation: "Limit Parking Outside Location",
    limitSnoozeThreshold: "Snooze Threshold",
    limitSuddenlyBreakThreshold: "Suddenly Break Threshold",
    limitUsingPhoneThreshold: "Using Phone Threshold",
    link: "Link",
    liveStream: "Live Stream",
    load: "Load",
    loadAmount: "Load Amount",
    loadpoint: "Load Point",
    loadStartAt: "Load Start At",
    location: "Location",
    Location: "Location",
    locationAndRoute: "Location and Route",
    locationCode: "Location Code",
    locationCodeIsRequired: " Location Code is required and can't be empty",
    locationGroup: "Location Group",
    locationGroupName: "Location Group Name",
    locationGroupNameIsRequired: " Location Group Name is Required",
    locationGroupTypeIsRequired: " Location Group Type is Required",
    locationInformation: "Location Information",
    LocationInSystem: "Location in system",
    locationName1IsRequired: " Location Name1 is required and can't be empty",
    LocationOfDeviceUser: "Affiliation Location of Driver",
    LocationOfVehicle: "Affiliation Location of Vehicle",
    locationShareFrom: "Share From",
    locationShareTo: "Share To",
    locationType: "Location Type",
    location_type: "Location Type",
    locationTypeName: "Location Type Name",
    locationTypeNameIsRequired: "Location Type Name is Required",
    login: "Login",
    login_fail: "Login Fail",
    login_success: "Login success",
    LoginhistoryReport: "Login History Report",
    logType: "Log types",
    logout: "Logout",
    longdoMapApiKey: "Longdo Map Api Key",
    longitude: "Longitude",
    longitudeIsRequired: " Longitude is Required",
    LongitudeIsRequiredAndCantBeEmpty:
      " Longitude is required and can't be empty",
    lookingForSomething: "Looking For Something?",
    lostConnectio: "Disconnected",
    low: "Low",
    lowQualityVideo: "Low Quality Video",
    loading: "Loading Data",
    latestConnection: "Latest connection",
  },
  locationType: {
    "-": "-",
    All: "All",
    BS: "Bus Stop Point",
    BS1: "Bus Stop 1",
    BS2: "Bus Stop 2",
    CarService: "Car Service",
    GasStation: "Gas Station",
    NoParking: "No Parking",
    OnSite: "On Site",
    ParkingLot: "Parking Lot",
    Plant: "Load point",
    PreparePoint: "Prepare Point",
    RestArea: "Rest Area",
    Site: "Deliver point",
    Temporary: "Location Temporary",
    StaffPickUpAndDropOffPoint: "Staff pick-up/drop-off point",
    ManyParking: "Man Parking",
    DefaultLocation: "Default Location",
    InformalLocation: "Informal Location",
  },
  m: {
    min_temp: "Maximum Temperature",
    max_temp: "Minumun Temperature",
    moveToQueue: "Move To Queue",
    m: "m.",
    ms2: "m/s²",
    m_april: "April",
    m_august: "August",
    m_december: "December",
    m_february: "February",
    m_january: "January",
    m_july: "July",
    m_june: "June",
    m_march: "March",
    m_may: "May",
    m_november: "November",
    m_october: "October",
    m_september: "September",
    machineSerialNumber: "Machine Serial Number",
    maintenance: "Maintenance",
    maintenance_status: "Status",
    maintenance_type: "Maintenance Type",
    maintenanceConditions: "Maintenance Conditions",
    maintenancedevice_report_menu: "Maintenance Devices",
    MaintenancedeviceconcludeReport: "Maintenance Device Report",
    MaintenanceReport: "Maintenance Report",
    manage_product: "Manage products",
    management_menu: "Management",
    managementdevice_report_menu: "Management Devices",
    map: "Map",
    mapType: "Map Type",
    mapView: "Map View",
    masterData: "Master data",
    match: "Match",
    max_speed: "Max speed",
    maxDrumCapacity: "Max Drum Capacity",
    medium: "Medium",
    message: "Message",
    meter: ["Meter", "Meter"],
    meterDashCameras: "meter",
    meterSN: "Meter S/N",
    meterStatus: "Meter Status",
    mileNo: "Mile No.",
    min: "Min",
    Minimal: "Minimal",
    minute: "Minute",
    miss: "Miss",
    mixingDrum: "Drum",
    model: "Model",
    moisture: "Humidity",
    monitoring: "Monitoring",
    month: "Month",
    monthly: "Monthly",
    more_10_mins: "> 10 mins",
    more_10_sec: "> 10 secs",
    more_1_min: "> 1 min",
    more_30_sec: "> 30 secs",
    more_5_mins: "> 5 mins",
    more_5_sec: "> 5 secs",
    more_60_sec: "> 60 secs",
    move: "Move",
    move_in: "Move in",
    move_out: "Move out",
    moveDevice: "Move Device",
    mr: "Mr.",
    mrs: "Mrs.",
    min_speed: "Max Speed (Minimum)",
    multiDropDescIncomingTrip: "Incoming",
    multiDropDescIncomingTripDesc: "",
    multiDropDescIncomingLateTrip: "Incoming (Late)",
    multiDropDescIncomingLateTripDesc: "",
    multiDropDescAlreadyVisitTrip: "Arrived",
    multiDropDescAlreadyVisitTripDesc: "",

    multiDropDescVisited: "Not yet Arrived",
    multiDropDescVisitedDesc: "",
    multiDropDescVisitedConfirm: "Waiting Confirm",
    multiDropDescVisitedConfirmDesc: "",
    multiDropDescVisitedLate: "Late Arrival",
    multiDropDescVisitedLateDesc: "",
    multiDropDescVisitedOnTime: "Arrived on time",
    multiDropDescVisitedOnTimeDesc: "",

    multiDropDescLeave: "Not yet Leave",
    multiDropDescLeaveDesc: "",
    multiDropDescLeaveConfirm: "Waiting Confirm",
    multiDropDescLeaveConfirmDesc: "",
    multiDropDescLeaveLate: "Leave (Late)",
    multiDropDescLeaveLateDesc: "",
    multiDropDescLeaveOnTime: "Leave on time",
    multiDropDescLeaveOnTimeDesc: "",
    manageDocument: "Manage Document",
    manageDocument2: "Manage Document",
    minutes: "minutes",

    manageRepairDevice: "Manage Repair Device",
    manageDepositFuelCredit: "Manage Deposit Fuel Credit",
    manageWithdrawFuelCredit: "Manage Withdraw Fuel Credit",
    monitorSDCard: "Monitor SDCard",
    manageTemplate: "Manage Template",
    monitorGPS: "Monitor %GPS",
    monitorGPSLog: "Monitor GPS log",
    maintenanceDevice: "Maintenance device",
    maintenanceDeviceHistory: "Maintenance device history",
  },
  n: {
    notificationTimeBeforeDueDate: "Notification time before due date",
    nope: "none",
    nodriver: "No Driver Name",
    name: "Fullname",
    nameAE: "Name AE",
    nameAndType: "Name & Type",
    nameFromDrivingID: "Driving ID Name",
    nameTableCompany: "Name",
    nameTitle: "Name Title",
    nationality: "Nationality",
    nationalityZone: "Nationality Zone",
    nearByLocation: "Nearby Location",
    nearbyPlants: "Nearby Plants",
    nearPlants: "Near Plants",
    new: "New",
    newCustomer: "New Customer",
    newJob: "New Job",
    newLocation: "New Location",
    newPassword: "New Password",
    newRoute: "New Route",
    newTicket: "New Ticket",
    newUser: "New User",
    newUserGroup: "New User Group",
    next: "Next",
    nextMaintenanceConditions: "Next maintenance conditions",
    nextRegisterDate: "Next Register Date",
    nightTime: "Night Time",
    no: "No",
    no_filter: "No filter",
    node: "Node",
    nodeName: "Node Name",
    noEntry: "No Entry",
    noExit: "No Exit",
    nofilevideo: "Unable to stream video at the selected time",
    none: "None",
    normalStatusImage: ["Normal Status Image", "Normal Status Image"],
    notAvailable: "Not Available",
    notification: "Notification",
    notify_date: "Notify date",
    notificationPasswordNotSafeInfo:
      "Your password not safe enought, We recommended you to change",
    notificationEndWhen: "Notification Close When",
    notificationTime: "Notification Time",
    notificationTracking: "Notification Tracking",
    notificationType: "Notification Type",
    notReadyForWork: "Not Ready For Work",
    nounsCar: "Cars",
    noVehicleSelect: "No Selection",
    now: "Now",
    number: "Quantity",
    number_of_days: "Number of days",
    numberOfAlarmsPerDay: "Number of Alarms Per Day",
    numberOfCardSwipeTimes: "Number of Card Swipe Times",
    numberOfContinuousAlarmDay: "Number of Continuous Alarm Days",
    NumberofdaysworkedReport: "Number of Days Worked Report",
    numberofEntryLatePoint: "Number of Entry Late Point (Point)",
    numberOfExpirationDates: "Number Of Expiration Dates",
    numberVehiclegroup: "Vehicle from visibility",
    numberTotalVehiclegroup: "Total vehicle in group",
    numberOfVisitors: "Number of visitors",
    notAllowed: "Not Allowed",
    notinotswipecard: "Noitifcation not swipe driver license",
    notSendToDLT: "Not Send To DLT",
    noReport: "No.",
    numberOfTimesDeviceOnline: "Number of times device online",
    numberOfTimesEngineStarted: "Number of times engine started",
    noRecording: "No recording",
  },
  o: {
    origin: "Origin",
    obd: "OBD",
    off: "off",
    offFilter: "Off",
    offFilterMeter: "Off",
    offline: "Offline",
    ok: "Yes",
    okay: "OK",
    on: "on",
    onDevelop: "On Develop",
    onFilter: "On",
    onFilterMeter: "On",
    online: "Online",
    onProcess: "On Process",
    open: "Open",
    opened: "Opened",
    openFilter: "Open Filter",
    operation: "Operation",
    operator: "Operator",
    or: "OR",
    orderCode: "Order Code",
    ordinal_numbers: "Ordinal No.",
    originToDestination: "Origin-Destination",
    other: "Other",
    outside: "Outside",
    outofcontact: "Declare offline device",
    over50: "Limit 50 vehicles",
    over100: "Limit 100 vehicles",
    over12Month: "Limit 12 month",
    over20: "Limit 20 vehicles",
    over30: "Limit 30 vehicles",
    over32Month: "Limit 3 years",
    over_hour: "Driving over hour",
    overdueSpeedInTheCommunityArea: "Speed Limited (Community Area)",
    overOneMonth: "Limit 1 month",
    over2Month: "Limit 2 month",
    overSpeedInNormalRoutes: "Speed Limited (General Route)",
    overSpeedInTheJunction: "Speed Limited (Junction)",
    overview: "Overview",
    ownerView: "Owner View",
    vehicleOffline: "Vehicle is offline",
    over5Days: "Limit 5 days",
    over200: "Limit 200 vehicles",
    over1Day: "Limit 1 day",
    operatorName: "Operator Name",
    over3Days: "Limit 3 days",
    over1Month: "Limit 1 month",
  },
  p: {
    parkingTime: "Parking Time",
    periodNotice: "Continuous driving time that triggers driver alerts",
    present: "present",
    powerSupply: "Power Supply",
    power: "Power",
    powerBankStatus: "Power Bank Status",
    piece: "piece",
    ParkingPointNo: "Parking Point No.",
    powerBank: "Power Bank",
    powerFromVehicle: "Power From Vehicle",
    progress: "Download Progress",
    parking: "Parking",
    ParkingReport: "Parking Report",
    part_type: "Part",
    partgroup: "Part Group",
    partiallyDamagedCard: "Card Can Not Be Used",
    partner: "Partner",
    partnerCar: "Partner",
    partSetting: "Part Setting",
    partType: "Part Type",
    passenger: "Passenger",
    password: "Password",
    passwordNotSafeValidateText: "Password Not Safe",
    passwordMustBeAtLeast6: "Password Must be At Least 6 Characters",
    passwordMustMatch: "Confirm Password Not Match",
    passwordsMustMatch: " Passwords must match",
    pdf: "PDF",
    product: "Product",
    pdfFilesUpTo5MBInSize: "PDF Files Up To 5MB In Size",
    pending: "Pending",
    performanceLog: "Performance Log",
    period: "Period",
    policyPeriod: "The continuous period of time that causes policy violations",
    policyPeriodFormat: "(hours:minutes:seconds)",
    periodOfInsuranceYear: "Period Of Insurance (Year)",
    permission: "Permission",
    personal: "Personal",
    personalID: "Personal ID",
    personalIDMustBeNumber: " Personal ID Must Be a Number",
    personalIDTooLong: " Too Long! Personal ID Must Be 13 Digits",
    personalIDTooShort: " Too Short!, Personal ID Must Be 13 Digits",
    personalInformation: "Personal Information",
    phoneNumber: "Phone Number",
    phoneNumberMustBeNumber: " Phone Number Must Be a Number",
    photo: "Photo",
    pickup: "Pick Up",
    pickupData: "Pick Up Date",
    pickUpDateTime: "Pick Up At",
    pickUpDetail: "Pick Up Detail",
    pickUpLocation: "Pick Up Location",
    pickUpTime: "Pick Up Time",
    picture: "Picture",
    pictureUpdate: "Picture Update",
    pinDown: "Pinned",
    plan_start_deliver_at: "Plan start deliver at",
    plant: "Plant",
    plantArrivingTime: "Plant Arriving Time",
    plantCode: "Plant Code",
    plantFactory: "Plant/Factory",
    plateNo: "Plate No.",
    plateNoOfInstall: "Plate no of install",
    plateNoOfUninstall: "Plate no of uninstall",
    pleaseChooseDateRange: " Please choose date range",
    pleaseChooseEvent: " Please choose event",
    pleaseChooseReport: " Please Choose Report",
    pleaseChooseVehicle: " Please choose vehicle",
    pleaseEnterEmailAddress: " Please enter e-mail address",
    pleaseEnterEmailSubject: " Please enter e-mail subject",
    pleaseSelect: "Please Select",
    pleaseSelectDriver: " Please select a driver",
    pleaseSelectExpirationDate: " Please select the expiration date",
    pleaseSelectLocation: " Please select location",
    pleaseSelectPicture: "Please select a picture.",
    pleaseSelectReportType: " Please select report type",
    pleaseSelectTheReport: " Please select the report",
    pleaseSelectVehicleCode: " Please select vehicle code",
    pleaseSelectVehiclePlateNo: " Please select plate no.",
    pleaseChooseCompany: " Please select company",
    pleaseChooseLog: "Please choose log",
    po: "PO",
    point: "Point",
    policy: "Policy",
    policyType: "Policy Type",
    polyGonRadius: "Polygon Radius",
    PONumber: "P/O Number",
    poNumberIsrequired: " PO Number is required",
    position: "Position",
    pos_update: "Pos Update",
    preventReDrawLocationConfirm: "Location will lost when re draw?",
    preventRePinLocationConfirm: "Location will lost when re pin?",
    preview: "Preview",
    previous: "Previous",
    privilegegroup: "Privilege Group",
    processing: "Processing",
    product_name: "Product name",
    product_type: "Product type",
    productType: "Product Type",
    profile: "Profile",
    province: ["Province", "Province"],
    pto: "PTO",
    publicKey: "Public key",
    pullvehiclesink: "Pulled Sink Vehicle History",
    purchaseChannel: "Purchase Channel",
    plateNoVehicleCode: "Plate No, Vehicle Code",
    parkingDateTime: "Parking Date time",
    parkingDuration: "Parking duration (Hours:Minutes:Seconds)",
    parkingDistance: "Distance (Km/h)",
    parkingSumDistance: "Sum Distance (Km/h)",
    parkingLocation: "Parking Location",
    product_description: "Description",
    product_code: "Code",
    product_name_require: "Name is require",
    product_name_duplicate_require: "Name is duplicate",
    product_code_require: "Code is require",
    product_code_duplicate_require: "Code is duplicate",
    product_uom_require: "Smallest Uom is require",
    plateNoIsNotvalid: "Plate No. is not valid",
    pleaseSelectVehicleRegistration: "Select Vehicle Registration",
    pickupAndDropoffEmployee: "Pickup/Drop-off employee",
    percentageOfTotalVideoRecorded: "Percentage of total video recorded",
  },

  product_types: {
    animal_food: "Animal Food",
    bag_cement: "Bag Cement",
    bulk_cement: "Bulk Cement",
    chill: "Chill",
    concrete_pump: "Concrete pump",
    container: "Container",
    fcr: "FCR",
    frozen: "Frozen",
    generic: "Generic",
    plate: "Plate",
    readymixconcrete: "ReadyMixConcrete",
    room_temp: "Room Temp",
    spool: "Spool",
    valuable: "Valuable",
  },
  q: {
    queue: "Queue",
    qty: "Qty",
    quantity: "Quantity",
    quantity_liter: "Quantity (liter)",
  },
  r: {
    ringTone: "Ring Tone",
    registry_number: "Registry Number",
    remainingTime: "Remaining Time",
    radS: "rad/s",
    radiuns: "Radius",
    radiunsM: "Radius (m.)",
    rainingTime: "Raining Time",
    reActivate: "Re-Activate",
    reactive_date: "Re-Active Date",
    received: "Received",
    receiveDate: "Receive Date",
    refuel: "Refuel",
    refuelAt: "Refuel At",
    RefuelReport: "Refuel Report",
    reFuelTitle: "Refuel",
    remainDay: "Remain (day)",
    remainingDevice: "Remaining Device",
    remainingRequest: "Remaining Request",
    remark: "Comment",
    remark1: "Remark 1",
    remark2: "Remark 2",
    remarkBooking: "Remark Booking",
    remindDate: "Remind Date",
    reminderData: "Reminder Date",
    reminderTime: "Reminder Time",
    renewalDate: "Renewal Date",
    RepairDevice: "Repair Device",
    replay: "Replay",
    replayDetail: "Replay Detail",
    replayNew: "Replay (New) ",
    report: "Report",
    report_daily: "Daily",
    report_monthly: "Monthly",
    report_name: "Report Name",
    report_taxifare: "Taxi Fare",
    report_weekly: "Weekly",
    report_yearly: "Yearly",
    reportWithdrawfuelcredit: "Report Withdraw Fuel Credit",
    reportFlow: "Report Water Flow Sensor",
    reportDepositfuelcredit: "Report Deposit Fuel Credit",
    reportAlarmFrequencyClassifiedByDailyDriver:
      "Report Alarm Frequency Classified by daily driver",
    reportCardSwipeBehaviorBeforeDrivingDaily:
      "Report Card Swipe Behavior Before Driving Daily",
    reportSummarizingTheNumberOfOffSiteParking:
      "The Number of Off-Site Parking Report",
    reportType: "Report Type",
    requestList: "Request List",
    require: "Require",
    requiredFieldCompany: " Company is required",
    requiredFieldConfirmPassword: " Confirm Password is required",
    requiredFieldDischargeDenominator: " Discharge Denominator is Required",
    requiredFieldEmail: " Email is required",
    requiredFieldFinishRefuel: " Finish Refuel is Required",
    requiredFieldFirstName: " First Name is required",
    requiredFieldFuelTankSize: " Fuel Tank Size is Required",
    requiredFieldLastName: " Last Name is required",
    requiredFieldLimitDrivingHourTime: " Limit Driving Hour Time is Required",
    requiredFieldName: " Name Title is required",
    requiredFieldPartType: " Part Type is Required",
    requiredFieldPassword: " Password is required",
    requiredFieldPersonalID: " Personal ID is Required",
    requiredFieldPhoneNumber: " Phone Number is invalid",
    requiredFieldPlateNo: " Plate No. is Required",
    requiredFieldQuantity: " Quantity is Required",
    requiredFieldStartRefuel: " Start Refuel is Required",
    requiredFieldTotalPrice: " Total Price is Required",
    requiredFieldUsername: " Username is required",
    requiredFieldVehicle: " Vehicle is Required",
    requiredFieldVehicleCode: " Vehicle Code is Required",
    requiredFieldVehicleGroupName: " Vehicle Group Name is Required",
    requiredFieldVehicleGroupCode: "Vehicle Code Name is Required",
    requiredFieldVehicleType: " Vehicle Type is Required",
    resendEmail: "Resend Email",
    reset: "Reset",
    rfid: "RFID",
    risk: "Risk",
    RiskbehaviourReport: "Risk Behaviour Report",
    round: "Round",
    route: "Route",
    route_iconsiam: "Route Iconsiam",
    route_master_name: "Route master name",
    routemaster: "Route Master",
    routeName: "Route Name",
    routeRadius: "Route Radius",
    routeRadiusM: "Route Radius (m.)",
    radiusIsRequire: "Require Radius",
    rpm: "RPM",
    running: "Running",
    routemaster_name_require: "Route master name is require",
    routemaster_name_duplicate: "Route master name is duplicate",
    report_gpsLostContact: "GPS Lost Contact",
    report_taxisos: "Taxi SOS",
    report_summarylinenotification: "Summary Line Notification",
    rtspAuthen: "RTSP Authen",
    reversing_light: "Reversing light",
    requiredField: "Required field",
    required: "Required",
    route_master_type: "Route master type",
    RTSPMain: "RTSP main",
    RTSPSub: "RTSP sub",
    RTSPThird: "RTSP 3rd",
    recordingMode: "Recording mode",
  },
  s: {
    soundPreview: "Sound Preview",
    speedToAlertTheDriver: "Speed To Alert The Driver (km/hr)",
    sim: "signal scale",
    satelliteCount: "count satellite",
    SummaryReport: "Summary Report",
    seeMore: "See More",
    showAndHide: "show/Hide",
    showAndHideLocation: "Show/Hide Location",
    setupFullTankOfFuel: "Setup Full Tank Of Fuel",
    saveTableSize: "Save Table Size",
    shippingInfo: "Shipping Info.",
    sort: "Sort",
    safety: "Safety",
    salesChannels: "Sales Channels",
    salesMan: "Salesman",
    satellite: "Satellite",
    save: "Save",
    saveNext: "Save & Next",
    scan_date_at: "Uploaded Date",
    SCCOCarComparisonReport: "SCCO car comparison report",
    schedule_time: "Schedule time",
    schedule_type: "Schedule Type",
    scheduled: "Scheduled",
    scheduled_day: "Schedule day",
    schedulereport: "Schedule Report",
    scoreEconomy: "Economy Score (Score)",
    scorePlanAchievement: "Plan Achievement Score (Score)",
    scoreSafety: "Safety Score (Score)",
    scoreTotal: "Total Score (Score)",
    search: "search",
    searchMenu: "Search menu",
    searchDashCameras: "Search Text from Plate No, Vehicle Code ...",
    searchDirections: "Search Directions",
    searchPlants: "Search",
    searchText: "Search Text",
    searchTextFromPlateNo: "Search Text From Plate No ...",
    searchModel: "Search Model",
    second_dest: "End Position",
    secondaryPhoneNumber: "2nd Phone Number",
    seenAll: "See All",
    select_event: "select event",
    selectAssignTo: "Select Assign To",
    selectCriticalityLevel: "Select Criticality Level",
    selectDate: "Select date",
    selected: "Selected",
    selectedDevice: "Selected Device",
    selectedVehicleAlready: "Selected Vehicle Already",
    selectFile: "Select File",
    selectNotificationChannel: "Select Notification Channel",
    selectOne: "Select One",
    selectPlateNo: "Select Vehicle",
    selectStatus: "Select Status",
    selectTime: "Select time",
    selectVehicleGroup: "Select Vehicle Group",
    send: "Send",
    send_data_before_delivery: "Use data from previous day",
    send_email: " Send email",
    sendAsAGroupOfVehicles: "By Vehicle Group",
    sender: "Sender",
    sendFailure: "Send Failure",
    sendSelectedVehicles: "By Selected Vehicle",
    sentBy: "Sent By",
    sentSuccessfully: "Sent Successfully",
    serviceinstalldevice: "Service Install Device",
    servicemovedevice: "Service Move Device",
    Servicerepairdevice: "Repair Inform",
    setTheRoute: "Set The Route",
    setting: "Setting",
    setting_filter: " Filter Setting",
    setup: "Set Up",
    shaft: "Shaft",
    sharedAt: "Shared At",
    shareFrom: "Share From",
    shareLocation: "Share Location",
    shareTo: "Share To",
    ship_to: "Ship to",
    shipTo: "Ship To",
    shock: "Shock",
    shopee: "Shopee",
    show: "Show",
    showAll: "Show All",
    showTraffic: "Show Traffic",
    simNumber: "ICCID",
    site: "Site",
    siteCode: "Site Code",
    sizeAndWeight: "Size And Weight",
    slideWrongCardType: "Invalid Card Type",
    slidingCard: "Card Swipe",
    smallTruck: "Small Truck",
    smsNotifyTo: "SMS Notify To",
    someparts: "Some Parts",
    sos: "sos",
    southeastAsia: "Southeast Asia",
    specialPrivilege: "Special Privilege",
    speed: "Speed",
    speedKmPerHr: "Speed (km./h)",
    speedLimited: "Speed Limited",
    speedOverLimit: "Speed Over Limit",
    speedOverLimitByDriver: "Speed Over Limit By Driver",
    speedOverLimitByVehicleGroup: "Speed Over Limit By Vehicle Group",
    speedOverLimitByVehicleType: "Speed Over Limit By Vehicle Type",
    SpeedoverlimitsummaryReport: "Speed Over Limit Summary Report",
    speedRange: "Speed Range (km./h)",
    speedRangeNoKm: "Speed Range",
    start_daily_time: "Daily start time",
    start_deliver_at: "Start Deliver At",
    startAndEndValidDateDesc: "Vehicles Using Period",
    startAt: " Start at",
    startDate: "Start Date",
    startDeliveryAt: "Start Deliver At",
    startDeliveryDay: "Start Deliver Date",
    startDestinationCode: "Start Location Code",
    startDestinationName: "Start Location Name",
    startLocationCode: "Start Location Code",
    startLocationName: "Start Location Name",
    startRefuel: "Start Refuel",
    startTrip: "Start Trip",
    startValidDate: "Start Valid Date",
    state: "State",
    status: "Status",
    status_sheet: "Status Sheet",
    status_download_file: "Status Download File",
    status_engineoff: "Engine off",
    status_moving: "Moving",
    status_parked: "Parked",
    status_vehicle: "Vehicle status",
    statusVehicleVideo: "Status",
    stopWithEngineOff: "Stop/Engine Off",
    stopWithEngineOn: "Stop/Engine On",
    submit: "Save",
    submit_name: "Submit name",
    submit_telno: "Submit telno",
    submitName: "Submit Name",
    submitTelno: "Submit Tel No",
    subType: "Sub Type",
    success: "Success",
    SummaryiconsiamReport:
      "Shuttle Boat – Shuttle Bus Service Average Summary Report",
    summaryInfo: "Summary Information",
    SummaryKPIByDriverReport: "KPI by Driver Report",
    SummaryKPIReport: "Summary KPI Report",
    support: "Support",
    surplus: "Surplus",
    startEndDate: "Start Date - End Date",
    selectData: "Select data",
    shell: "Shell",
    serverIP: "Server/IP",
    serverPath: "Path",
    seconds: "seconds",
    specify_reason_not_send_dlt:
      "Please specify the reason for not submitting to DLT.",
    search_by: "Search by",
    search_plate_no: "Search PlateNo",
    search_driver: "Search Driver",
    search_trip: "Search Trip",
    summaryDrivingLogReport: "Summary Driving Log Report",
    startTime: "Start time",
    selectAll: "Select all",
    showLocationName: "Show location name",
    showOnlyDataLessThan100: "Show only data less than 100",
    selectTemplate: "Select Template",
    site_name: "Site name",
  },
  scheduleReport: {
    excel: "Excel",
    pdf: "PDF",
    report_comparevehiclescco: "General >> Compare Vehicle SCCO Report",
    report_dailyiconsiam:
      "General >> Shuttle Boat – Shuttle Bus Service Daily Report",
    report_distfromlastmaintenance: "General >> Distfromlastmaintenance Report",
    report_dozeoffrisk: "General >> Doze off risk Report",
    report_driverinformation: "General >> Driverinformation Report",
    report_drivingdistance: "General >> Driving Distance Report",
    report_drivinghour: "General >> Driving hour Report",
    report_event: "General >> Event Report",
    report_maintenance: "General >> Maintenance Report",
    report_numberofdaysworked: "General >> Number of days worked Report",
    report_parking: "General >> Parking Report",
    report_summaryiconsiam:
      "General >> Shuttle Boat – Shuttle Bus Service Average Summary Report",
    report_temperature: "General >> Temperature Report",
    report_truckcurrentlocation: "General >> Current Address Report",
    report_truckusage: "General >> Truck Usage Report",
    report_truckusage_sjc_monthly: "General >> SJC Monthly Truck Usage  Report",
    report_summarylinenotification: "General >> Summary Line Notification",
    report_fuelremainrate: "General >> Fuel Remain Rate Report",
    report_truckengineon: "Efficiency >> Truck Engine On Report",
    report_truckengineoff: "Efficiency >> Truck Engine Off Report",
  },
  t: {
    tax: "Tax",
    taxno: "Tax no.",
    TotalDistance: "Total Distance",
    tagID: "Tag ID",
    taxiMeter: "Taxi Meter",
    taxNo: "Tax No.",
    technician: "Technician",
    telnoAE: "Telno AE",
    temperature: "Temperature",
    temperature1: "Temp. 1",
    temperature2: "Temp. 2",
    TemperatureReport: "Temperature Report",
    timeGoToLocation: "Plan Time",
    theDayThatTheCarPulled: "The Day That The Car Pulled",
    theDriverIsIicenseReportHasExpired:
      "The Driver's License Report Has Expired",
    theRoute: "Route",
    TheUsualRoute: "The Usual Route",
    trackingLink: "Tracking Link",
    thisMonth: "This Month",
    thisYear: "This Year",
    ticket: "Ticket",
    time: "Time",
    time_1440: "24 hrs",
    time_15: "15 mins",
    time_180: "3 hrs",
    time_30: "30 mins",
    time_300: "5 hrs",
    time_420: "7 hrs",
    time_60: "1 hr",
    time_720: "12 hrs",
    time_entry: "Entry time",
    time_out: "Out time",
    time_period: "Time Period",
    time_travel: "Travel time",
    time_use_in_location: "Time in location",
    times: "Times",
    timeThatCanBeUsed: "Time That Can Be Used.",
    timeZone: "Time Zone",
    tire: "Tire",
    title: "Title",
    titleAccount: "Account",
    to: " To ",
    to_email: "To",
    today: "Today",
    toggleHeatMap: "Toggle Heat Map",
    tooLong50: " Too Long, Value must less than 50",
    tooLong25: " Too Long, Value must less than 25",
    tooLong20: " Too Long, Value must less than 20",
    tooShot8: " Too Shot, Value must More than 8",
    tokenExpired: "Session Expired",
    tool: "Tool",
    tools: "Tools",
    top5AlertReportsOfEachTypeDividedByMonthlyTimePeriod:
      "Top 5 Alert Reports of Each Type Divided by Monthly Time Period",
    topCustomer: "Top Customer",
    topEarning: "Top Earning",
    toSiteDistance: "To Site Distance",
    total: "Total",
    totalDistance: "Total Distance (km/h)",
    totalDuration: "Total Duration (Day:Hour:Minutes)",
    totalEmergencyCase: "Total Emergency Case",
    totalNumberofMinutesEnteredSlowly:
      "Total Number of Minutes Entered Slowly (Min)",
    totalPrice: "Total Price",
    totalPrice_baht: "Total Price (Baht)",
    tracking: "Tracking",
    TrackingdataReport: "Tracking Data Report",
    trackingNo: "Tracking No.",
    trainer: "Trainer",
    trainingDate: "Training Date",
    traininglist: "Training List",
    trip: "Trip",
    tripCodeDup: "Trip Code Exists",
    tripInfo: "Trip Info",
    tripStartAt: "Trip Start At",
    trip_date: "Duration Date Trip",
    trip_duration: "Start at - End at",
    trip_duration_management: "Set a trip duration",
    trip_report_menu: "Trip",
    TripbyengineoffonReport: "Trip By Engine On - Off Report",
    tripCode: "Trip Code",
    tripInfoDateTime: "Trip Information Date/Time",
    tripStatus: "Trip Status",
    tripCannotEditDueToOverTime: "Cannot Edit Trip due to Over Time",
    TripsumbylocationReport: "Trip Summary by Location Report",
    TripsumbyvehReport: "Trip Summary Seperated by Vehicle Report",
    TripsummaryReport: "Trip Summary Report",
    TriptemperatureReport: "Trip Temperature Report",
    truckCategory: "Truck Category",
    TruckcurrentlocationReport: "Current Address Report",
    truckDriverMatching: "Truck Driver Matching",
    TruckengineoffReport: "Truck Engine Off Report",
    TruckengineonReport: "Truck Engine On Report",
    TrucknotuseReport: "Truck Not Use Report",
    truckType: "Truck Type",
    TruckusageReport: "Truck Usage Report",
    TruckusageSJCMonthlyReport: "Truck Usage SJC Monthly Report",
    type: "Type",
    type_duration: "By Duration",
    type_person: "By Person",
    type_problem: "Type Problem",
    type_report: "Type Report",
    type_trip: "By Trip",
    typeLocation: "Type Location",
    typeMaintenance: "Type",
    temporaryTracking: "Temporary Tracking",
    telSetting: "Telephone Setting",
    temperatureOffset: "Temperature Offset",
    temperatureSetting: "Temperature Setting",
    tip_code: "Trip code",
    technician_name: "Technician name",
    technician_telno: "Technician telno",
    totalMinutesOfVideoRecorded: "Total minutes of video recorded",
    totalMinutesEngineRunning: "Total minutes engine running",
    timePeriod: "Time period",
    ticketTime: "Ticket time",
    ticketOrder: "Ticket order",
    ticketPlantName: "Plant name",
  },
  time: {
    seconds: "seconds",
    minutes: "minutes",
  },
  u: {
    unassign: "Unassign",
    unBroken: "Unbroken",
    uninstall: "Uninstall",
    unitPrice: "Unit Price (Baht)",
    unknown: "Unknown",
    unMatch: "Un Match",
    updatedAt: "Updated At",
    updated_driving_license_at: "Update Driving License At",
    updatedBy: "Modified By",
    updateLocation: "Update A Location",
    updatePictures: "Update A Photo",
    upload: "Upload",
    uploaderIsRequired: " Uploader is a required",
    use_account_name: "Account name",
    use_date_time: "Action date time",
    user: "User",
    userClose: "User Closed",
    userExpire: "User Expire",
    userExpireAt: "User Expire At",
    userGroup: "User Group",
    userGroupPremission: "User Group Permission",
    userName: "Username",
    userProfile: "User Profile",
    uom_name: "Name",
    uom_description: "Description",
    uom: "UOM",
    uom_name_require: "Name is require",
    uom_name_duplicate_require: "Name is duplicate",
    uom_smallest: "Smallest Uom",
    userLogonReport: "user history report",
    uploadWhenConnectedWifi: "Upload Video When Connected Wifi",
    uploadVideoCellular: "Upload Video Cellular",
    userV1: "Username V1",
    userV2: "Username V2",
    uploadFile: "Upload File",
    uploadYourFile: "Upload Your File",
    updateSuccess: "Update success",
    updateFail: "Update failed",
  },
  v: {
    voiceManagement: "Voice Management",
    validDate: "Valid Date",
    valueIsRequired: " Value is required and can't be empty",
    vehicle: "Vehicle",
    vehicle_type: "Vehicle Type",
    vehicleAndDriver: "Vehicle & Driver",
    vehicleCode: "Vehicle code",
    vehicleCertNumber: "Vehicle Certificate Number",
    vehicleColor: "Vehicle Color",
    vehicleDetail: "Vehicle Detail",
    vehicleGroup: "Vehicle Group",
    vehicleGroupCode: "Vehicle Group Code",
    vehicleGroupName: "Vehicle Group Name",
    VehicleinareaReport: "Vehicle in Area Report",
    VehicleinformationdltReport: "Vehicle Information for DLT",
    vehicleModel: "Vehicle Model",
    VehicleperformanceconcludeReport: "Vehicle Performance Conclude Report",
    vehicleRegisterType: "Vehicle Register Type",
    vehicleSharing: "Vehicle Sharing",
    vehicleStatus: "Vehicle Status",
    vehicleType: "Vehicle Type",
    VelocitysummaryReport: "Velocity Summary Report",
    vendor: "Vendor",
    verifyYourEmailAddress: "Verify Your Email Address",
    version: "Version",
    video: "MDVR Live Stream",
    videos: "Videos",
    videospecial: "MDVR Live Stream (Special)",
    video_type: "Video Type",
    videoDownload: "Video Download",
    view: "View",
    vnCode: "VN Code",
    voluntary: "Voluntary",
    vol: "Vol",
    vehicleGreaterSpeed: "Vehicle at greater speed (km/h)",
    videoSetting: "Video setting",
    videoSize: "Video Recevice Size(GB)",
    videoSnapshotReport: "Snapshot Report",
    vehiclePlateNo: "Vehicle plate no.",
    vehicleData: "Vehicle data",
    videoTime: "Video time",
    vehicleEntryAlert: "Vehicle entry alert",
    vehicleCodeAlreadyExists: "Vehicle code already exists",
  },
  policy: {
    default: "default",
    night: "night",
    raining: "raining",
    downhill: "downhill",
    uphill: "uphill",
    restricted_area: "restricted area",
    green_zone: "green zone",
    speed_limit_area: "speed limit area",
    stop: "stop",
    idle: "idle",
    pto_gate1_on: "pto gate1 on",
    boom_gate2_on: "Boom gate2 on",
    fuel_anomaly: "fuel anomaly",
    temperature: "temperature",
    working_within_a_given_period: "working within a given period",
    work_longer_than_the_time_limit: "work longer than the time limit",
    work_distance_less_than_rule: "work distance less than rule",
    vehicle_move_out_factory_long_time: "vehicle move out factory long time",
    vehicle_disconnect: "vehicle disconnect",
    vehicle_continous_driving: "vehicle continous driving",
    no_parking_area: "no parking area",
    risk_area: "risk area",
    accelerate_brake: "Sudden acceleration/braking",
    speed_limit_of_the_event: "speed limit of the event",
    stay_on_the_site_for_longer_than_the_allotted_time:
      "stay on the site for longer than the allotted time",
    the_drum_idling_during_the_delivery_in_transit:
      "the drum idling during the delivery in transit",
    stay_on_the_plant_for_longer_than_the_allotted_time:
      "stay on the plant for longer than the allotted time",
    return_for_longer_than_the_allotted_time:
      "return for longer than the allotted time",
    unauthorized_discharge: "unauthorized discharge",
    delivery_longer_than_the_allotted_time:
      "delivery longer than the allotted time",
    vehicle_not_ready_at_working_time: "vehicle not ready at working time",
    vehicle_not_deliver_at_plan_time: "vehicle not deliver at plan time",
    vehicle_not_arrive_site_at_plan_time:
      "vehicle not arrive site at plan time",
    alert_when_vehicle_not_follow_maintenance_rule:
      "alert when vehicle not follow maintenance rule",
    //
    speed_limit_and_zone: "speed limit and zone",
    event: "event",
    speed_limit_of_the_event: "speed limit of the event",
    trip: "trip",
    maintenance: "maintenance",
    outsidePolicy: "without the Location",
    insidePolicy: "within the Location",
    notSpecified: "Not specified",
  },
  variable: {
    abnormal_behavior: "Abnormal Behavior",
    boom: "BOOM",
    compulsory_motor_insurance: "Compulsory Motor Insurance",
    driving: "Driving",
    driving_hour: "Driving Hour",
    engine_oil: "Engine Oil",
    event: "Event",
    fuel: "Fuel",
    high: "High",
    insurance: "Insurance",
    insuranceno: "Insurance no.",
    invalidCredentials: "invalid Credentials",
    invalidOldPassword: "invalid Password",
    low: "Low",
    maintenance_vehicle: "Vehicle",
    medium: "Medium",
    No: "No",
    no_entry: "No Entry",
    no_exit: "No Exit",
    off: "off",
    on: "on",
    parking: "Parking",
    parking_area: "Parking Area",
    Plant: "Plant",
    prohibited_area: "Prohibited Area",
    pto_door: "PTO/Doors",
    safety: "Safety",
    sensor: "Sensor",
    Site: "Site",
    sos: "SOS",
    speed: "Speed",
    speed_limit: "Speed Limit",
    speed_limit_area: "Speed Limit In Area",
    stop: "Stop",
    stop_engine_on: "Stop With Engine On",
    stop_engine_on_with_area: "Stop Engine On with Area",
    stop_with_area: "Stop Inside Area",
    stop_with_engine_off: "Stop With Engine Off",
    abnormal_temperature: "Abnormal Temp.",
    success: "Success",
    suspension: "Suspension",
    taxi: "Taxi",
    temperature: "Temperature",
    timeline_aggressive_acceleration: "Aggressive acceleration",
    timeline_speed_over: "Speed over",
    timeline_suddenly_break: "Suddenly break",
    timeline_vehicle_stop_with_engine_on: "Vehicle stop with engine on",
    tire: "Tire",
    voluntary_motor_insurance: "Voluntary Motor Insurance",
    Yes: "Yes",
  },
  vehicleType: {
    backhoe: "Backhoe",
    bulk_cargo: "Bulk Cargo",
    bus: "Bus",
    car_6wheelers_trailer: "6 Wheelers Drive Trailer",
    caravan_10wheelers: "Caravan 10 Wheelers",
    cargo_ship: "Cargo Ship",
    cash_transporter_cmc01: "CASH Transporter - CMC 01",
    cash_transporter_cmc02: "CASH Transporter - CMC 02",
    cash_transporter_g45: "CASH Transporter - G4S",
    cash_transporter_lrd130: "CASH Transporter - LRD130",
    cash_transporter_test01: "CASH Transporter - Test 01",
    cement_bags_transporter_10wheelers: "Cement Bags Transporter 10 Wheelers",
    cement_powder_banana_shaped_trailer: "Cement Powder Banana Shaped Trailer",
    cement_powder_full_trailer: "Cement Powder Full Trailer",
    concrete_pump: "Concrete Pump",
    container_truck_10wheelers: "Container Truck 10 Wheelers",
    container_truck_6wheelers: "Container Truck 6 Wheelers",
    drive_10wheelers: "10 Wheelers Drive",
    drive_12wheelers: "12 Wheelers Drive",
    drive_18wheelers: "18 Wheelers Drive",
    drive_3wheelers: "3 Wheelers Drive",
    drive_6wheelers: "6 Wheelers Drive",
    dump_truck: "Dump Truck",
    express_4wheelers: "Express 4 wheelers",
    fjsldk: "fjsldk",
    flatbed_car: "Flatbed Car",
    forage_transporter_10wheelers: "Forage Transporter 10 Wheelers",
    forage_transporter_12wheelers: "Forage Transporter 12 Wheelers",
    forage_transporter_18wheelers: "Forage Transporter 18 Wheelers",
    forage_transporter_22wheelers: "Forage Transporter 22 Wheelers",
    forage_transporter_trailer: "Forage Transporter Trailer",
    forklift: "Forklift",
    freeze_truck_4wheelers: "Freeze Truck 4 Wheelers",
    freeze_truck_6wheelers: "Freeze Truck 6 Wheelers",
    harvestar: "Harvestar",
    hazardous_materials_10wheelers: "Hazardous Materials 10 Wheelers",
    hazardous_materials_trailer: "Hazardous Materials Trailer",
    high_4wheelers: "High 4 Wheelers Drive",
    isuzu: "Isuzu",
    jumbo_4wheelers: "Jumbo 4 Wheelers Drive",
    mini_4wheelers: "Mini 4 Wheelers Drive",
    mix_trailer: "Mix Trailer",
    motorcycle: "Motorcycle",
    ngv_10wheelers: "NGV 10 Wheelers",
    pickup_4wheelers: "Pickup 4 wheelers",
    rmx_truck_10wheelers: "RMX Truck 10 Wheelers",
    rmx_truck_4wheelers: "RMX Truck 4 Wheelers",
    rmx_truck_6wheelers: "RMX Truck 6 Wheelers",
    sedan: "Sedan",
    tail: "Tail",
    taxi: "Taxi",
    tractor: "Tractor",
    type_test: "type_test",
    van: "Van",
    van_dont_send_department: "Van(Do not send department)",
    van_tpt: "Van TPT",
  },
  w: {
    warningForVehiclesDepartingFromTheLane:
      "Warning for vehicles departing from the lane",
    warningAudioForParkingTimeComplete:
      "Warning Audio For Parking Time Complete",
    warningAudioForNotStoppingInTime: "Warning Audio For Not Stopping In Time",
    wrongSpeedAccordingToPolicy: "Wrong Speed According To Policy (km/hr)",
    waiting: "Waiting",
    watchVideoRealTime: "Watch Video Real Time",
    w: "W",
    wait: "Wait",
    w_friday: "Friday",
    w_monday: "Monday",
    w_saturday: "Saturday",
    w_sunday: "Sunday",
    w_thursday: "Thursday",
    w_tuesday: "Tuesday",
    w_wednesday: "Wednesday",
    waitAssessment: "Wait Assessment",
    waitForEmailChecking: "Wait For Email Checking",
    waitingConfirm: "Waiting Confirm",
    waitingForJob: "Waiting",
    warrantyEnd: "Warranty End",
    week: "Week",
    weekly: "Weekly",
    weWillContactYouAtTheTelephoneNumberProvided:
      "We will contact you at the telephone number provided.",
    wheel: "Wheel",
    whichMayHaveToContactTheAppointmentLocationAgain:
      "Which may have to contact the appointment location again.",
    wigth: "Wigth",
    workingTime: "Working Time",
    workingTimeActual: "Actual Working Time",
    workOverTime: "Overtime",
    wifiSetting: "Wifi Setting",
    wifiConnectionSetting: "Wifi Connection Setting",
    wifiSSID: "Wifi SSID",
    windshield_viper: "Windshield viper",
    withdrawfuelcredit: "Withdraw Fuelcredit(L)",
    widthMain: "Width main",
    widthSub: "Width sub",
  },
  x: {},
  y: {
    year: "Year",
    yearly: "Yearly",
    yes: "Yes",
    youCanOonlyUploadExcelFile: "You Can Only Upload EXCEL File!",
    youCanOonlyUploadPdfFile: "You Can Only Upload PDF File!",
    youCanOnlyUploadPNGorPDFfiles:
      "You Can Only Upload PNG, JPG, JPEG, PDF files",
  },
  z: {
    zone: "Zone",
    zoneName: "Zone Name",
    zoneNameIsRequired: "Zone Name is Required",
    zoomIn: "Zoom in",
    zoomInForMoreInfo: "Zoom in for use Feature",
    zoomOut: "Zoom out",
  },

  weight_uom: {
    g: "Gram",
    kg: "Kilogram",
    weight_uom: "Weight Uom",
    weight_uom_require: "Weight Uom is require",
  },

  sku: {
    from_uom: "Uom",
    weight: "Weight",
    quality: "Quality",
    from_uom_require: "Uom is require",
    weight_require: "Weight is require",
    quality_require: "Quality is require",
    uom_require: "Uom is require",
  },

  trip_import: {
    error_import: "Something went wrong",
    trip_code_require: "Trip code is require",
    vehicle_code_require: "Vehicle is require",
    date_start_trip_at_require: "Start Deliver Date is require",
    time_start_trip_at_require: "Start Deliver Time is require",
    do_number_require: "Invoice number is require",
    location_code_require: "Location Code is require",
    location_name_require: "Location Name is require",
    product_code_require: "Product Code is require",
    product_name_require: "Product Name is require",
    quality_require: "Quality is require",
    sku_require: "UOM is require",
    date_arrive_at_require: "Arrive Date is require",
    time_arrive_at_require: "Arrive Time is require",
    date_out_at_require: "Move Out Date is require",
    time_out_at_require: "Move OUt Time is require",
    confirm_send_require: "Sending Confirm By is require",
    vehicle_code_not_find: "Vehicle Code is not in system :",
    vehicle_code_not_install: "Vehicle Code is not install :",
    location_code_not_find: "Location Code is not found :",
    product_code_not_find: "Product Code is not in system :",
    sku_not_find: "Uom is not in system :",

    date_start_trip_at_format_incorrect:
      "Start Deliver Date is format incorrect :",
    time_start_trip_at_format_incorrect:
      "Start Deliver Time is format incorrect :",
    date_arrive_at_format_incorrect: "Arrive Date is format incorrect :",
    time_arrive_at_format_incorrect: "Arrive Time is format incorrect :",
    date_out_at_format_incorrect: "Move Out Date is format incorrect :",
    time_out_at_format_incorrect: "Move OUt Time is format incorrect :",

    remark_more_than_50: "Remark must not exceed 50 characters",
  },

  managedocument_import: {
    error_import: "Something went wrong",
    No_vehicle_code_in_system: "No vehicle code in system",
    Vehicle_code_is_required: "Vehicle code is required",
    Vehicle_plate_no_is_required: "Vehicle plate no is required",
    Tax_Expire_Date_is_required_when_tax_is_provided:
      "Tax expire date is required when tax no is provided",
    Act_Expire_Date_is_required_when_act_is_provided:
      "Act expire date is required when Act no is provided",
    Insurance_Expire_Date_is_required_when_insurance_is_provided:
      "Insurance expire date is required when Insurance no is provided",
    Tax_number_is_required_when_tax_is_provided:
      "Tax no is required when tax is provided",
    Act_number_is_required_when_act_is_provided:
      "Act no is required when act is provided",
    Insurance_number_is_required_when_insurance_is_provided:
      "Insurance no is required when insurance is provided",
    Tax_format_is_incorrect: "Tax format is incorrecte (dd/mm/yyyy)",
    Act_format_is_incorrect: "Act format is incorrecte (dd/mm/yyyy)",
    Insurance_format_is_incorrect:
      "Insurance format is incorrecte (dd/mm/yyyy)",
  },

  concreteDeliveryStatus: {
    loadDuration: "Load duration",
    loadFinishTime: "Load finish time",
    truckWashTime: "Truck wash time",
    plantDepartureTime: "Plant departure time",
    distance: "Distance",
    siteArrivalTime: "Site arrival time",
    waitDurationBeforeUnload: "Wait duration before unload",
    unloadDuration: "Unload duration",
    unloadFinishTime: "Unload finish Time",
    siteDepartureTime: "Site departure time",
    noSiteEntry: "No site entry",
    dischargeNotDetected: "Discharge not detected",
    noSiteDeparture: "No site departure",
    noReturnTrip: "No return trip",
    waitingForLoading: "Waiting for loading",
    connectionLost: "Lost Connection",
  },
  loadTimeStatus: {
    loadStartTime: "Load Start Time",
    loadFinishTime: "Load finish time",
    quantity: "Quantity",
  },
  plantMoveOutStatus: {
    plantDepartureTime: "Plant departure time",
    eTDToPlant: "ETD to Plant",
  },
  onTheWayStatus: {
    speedLimit: "Speed limit",
    late: "Late",
  },
  custMoveInStatus: {
    siteArrivalTime: "Site Arrival Time",
    etaToSite: "ETA to Site",
  },
  unloadTimeStatus: {
    unloadStartTime: "Unload start time",
    unloadFinishTime: "Unload finish time",
    duration: "Duration",
    quantity: "Quantity",
    total: "Total",
    unauthorizedDischarge: "Unauthorized discharge",
  },
  custMoveOutStatus: {
    siteDepartureTime: "Site departure time",
    etdToSite: "ETD to Site",
  },
  manageOTP: {
    phoneNumber: "Phone Number",
    companyName: "Company Name",
    searchCompany: "Search Company",
    totalVehicle: "Total Vehicle",
    matchPhoneNoWithVehicle: "Match PhoneNumberWithVehicle",
    pleaseFieldPhoneNumber: "Please Field Phone Number",
    pleaseField10PhoneNumber: "Please Field 10-digit Phone Number",
    pleaseFieldCorrectPhoneNumber: "Please Field Correct Phone Number",
    updateAt: "Updated At",
    manageOTP: "Mange OTP",
  },
  manageFuelRate: {
    consumptionWhileParking: "Consumption while parking (L per hr)",
    consumptionWhileRunning: "Consumption While Running (km per L)",
    vehicleModel: "Brand Model",
    vehicleGroupNumber: "Vehicle Group Number",
    manageFuelRate: "Mange Fuel Rate",
  },
  sdcardStatus: {
    normal: "Normal",
    broken: "Broken",
    none: "-",
  },
  iconsiam: {
    route: "Route",
    name_th: "Route name (th)",
    name_en: "Route name (en)",
    name_ch: "Route name (ch)",
    start_location: "Origin",
    end_location: "Destination 1",
    end_location_2: "Destination 2",
    distance: "Distance (km.)",
    parking: "Parking (min.)",
    duration_point: "ETA (min.)",
    duration: "Duration (min.)",
    assign_vehicle: "Assign vehicle",
    service_time: "Service Time",
    pier: "Pier",
    schedule: "Schedule",
    color: "Color",
    icon: "Icon",
    priority: "Priority",
    active: "Active",
    unavailable: "Unavailable",
    unavailable_msg_th: "Unavailable message (TH)",
    unavailable_msg_en: "Unavailable message (EN)",
    add_vehicle: "Add vehicle",
    boat_type: "Type",
    previous: "Previous",
    min_value: "Must be greater than 0",
  },
  replayNewV3: {
    speed_over_limit: "speed over limit",
    timeline_aggressive_acceleration: "timeline aggressive acceleration",
    boom_ready: "boom ready",
    turn_left_signal_on: "turn left signal on",
    turn_right_signal_on: "turn right signal on",
    honk: "horn",
    low_beam_on: "low beam on",
    high_beam_on: "high beam on",
    windshield_wipers_on: "windshield wipers on",
    windshield_wipers: "windshield wipers",
    reversing_light_on: "reversing light on",
    hit_the_brake: "hit the brake",
    hard_brake: "hard brake",
    discharge_concrete: "discharge concrete",
    pto: "PTO",
    sliding_card: "sliding card",
    not_sliding_card: "not sliding card",
    moving: "moving",
    idling: "idling",
    stop: "stop",
    Factory: "Factory",
    Plant: "Loading",
    Fuel: "Fuel Station",
    Maintenance: "Maintenance",
    Parking: "Parking",
    NoParking: "No Parking",
    BusStop: "Bus Stop",
    PickUpPoint: "Pick Up Point",
    Site: "Delivery Point",
    PlantOnSite: "Plant On Site",
    ManyParking: "Many Parking",
    Temporary: "Temporary Location",
    PreparePoint: "Prepare Point",
    OverSpeed: "Over Speed",
    SOS: "SOS",
    Hrashbreak: "Hrash break",
    PTO: "PTO",
    Moving: "Moving",
    Idling: "Idling",
    Stop: "Stop",
    SwipingCard: "Swiping Card",
    NotSwipingCard: "Not Swiping Card",
    OutsideTheArea: "OutsideTheArea",
    Boom: "BOOM",
    DischargeConcreate: "Discharge Concreate",
    TurnLeft: "Turn Left",
    TurnRight: "Turn Right",
    Horn: "Horn",
    LowBeam: "Low Beam",
    HighBeam: "High Beam",
    GPSUnavailable: "GPS Unavailable",
    WindShield: "Wind Shield",
    ReversingLight: "Reversing Light",
    Iding: "Iding",
    BOOM: "BOOM",
    DischargeConcrete: "Discharge Concrete",
    SpeedGraph: "Speed Graph",
    FuelGraph: "Fuel Graph",
    TempGraph: "Temp Graph",
    engineStatus: "Engine Status",
    parking_area: "Parking Area",
    speed_limit: "Speed Limit",
    drive_surge: "Drive Surge",
    Break: "Break",
    engineOn: "Engine On",
    engineOff: "Engine Off",
    running: "Running",
    stopped: "Stop",
    accAlarm: "ACC Alarm",
    slidingCard: "Sliding Card",
  },
  manageDashboardMonitoring: {
    DisplayName: "Display Name",
    Channel: "Channel",
    CameraNumber: "Camera Number",
    GroupName: "Group Name",
    PlateList: "Plate No List",
  },
  orc: {
    configFuelcredit: "Config fuel credit page",
    initFuelCredit: "Initial FuelCredit",
    withdrawFuelLimit: "Withdraw Fuellimit",
    depositReason: "Deposit Reason",
  },
  cameraPosition: {
    front: "Front camera",
    back: "Back camera",
    left: "Left camera",
    right: "Right camera",
  },
};

export { en };
